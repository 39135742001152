// import { ajax } from '../../ajax';
import { ShareEquipmentRequest } from './types';

// const url = ajax.appBaseUrl;

export default class EquipmentService {
  static async share(data: ShareEquipmentRequest) {
    // return ajax.post<ShareEquipmentResponse>(`${url}/equipment/share`, data);
    return new Promise(res => {
      setTimeout(() => {
        res(1);
      }, 2000);
    });
  }
}
