import React from 'react';
import { Tab } from './type';
import './BaseTabs.less';
import { BaseButton } from 'app/components/buttons/BaseButton';
import { CountDisplay } from 'app/components/badges';
import clsx from 'clsx';

interface Props<T> {
  className?: string;
  tabs: Tab<T>[];
  value?: T;
  onChange: (value: T) => void;
}

export default function BaseTabs<T extends string>({
  className,
  tabs,
  value,
  onChange,
}: Props<T>) {
  function renderTab({ label, badgeContent, value: tabValue }: Tab<T>) {
    return (
      <BaseButton
        aria-selected={value === tabValue}
        className="base-tabs__button"
        onClick={() => onChange(tabValue)}
      >
        <span className="base-tabs__label">{label}</span>

        {badgeContent !== undefined && (
          <CountDisplay
            className="base-tabs__badge"
            badgeContent={badgeContent}
          />
        )}
      </BaseButton>
    );
  }

  return (
    <div className={clsx('base-tabs', className)}>{tabs.map(renderTab)}</div>
  );
}
