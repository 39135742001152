import React from 'react';
import NoMachinesEmptyState from 'app/components/access-sharing/EquipmentAndFleets/NoMachinesEmptyState';
import { LayoutContext } from 'app/components/ReactContexts/layoutContext';
import {
  AccessSharingStepController,
  EquipmentSelectionStepController,
  EquipmentsSharingOverviewStepController,
} from './components';
import { useNavigate } from 'react-router-dom';
import {
  AccessSharingFlowProvider,
  useAccessSharingFlowContext,
} from 'app/hooks/access-sharing';
import { ACCESS_SHARING_EQUIPMENT_STEPS } from './constants';
import useNavigationPrompt from 'app/hooks/useNavigationPrompt';
import { ConfirmNavigateAwayDialog } from 'app/components/dialogs';
import { dummyEquipmentData } from 'app/constants/access-sharing';
import { useIsMobile } from 'app/hooks/useIsMobile';

export default function ShareEquipmentPage() {
  const { setClassNames, removeClassNames } = React.useContext(LayoutContext);
  const isMobile = useIsMobile();

  // TODO throw it away as soon as possible
  // Refactor layout components in the future to be more flexible
  // and avoid hacks like this.
  React.useEffect(() => {
    let classNameIndex = -1;

    if (!isMobile) {
      classNameIndex = setClassNames('layout-grey');
    }
    return () => {
      if (classNameIndex >= 0) {
        removeClassNames(classNameIndex);
        classNameIndex = -1;
      }
    };
  }, [isMobile]);

  return (
    <AccessSharingFlowProvider
      stepCount={Object.keys(ACCESS_SHARING_EQUIPMENT_STEPS).length}
      // startStep={ACCESS_SHARING_EQUIPMENT_STEPS.OVERVIEW}
    >
      <StepRenderer />
      <UnsavedChangesWarning />
    </AccessSharingFlowProvider>
  );
}

function StepRenderer() {
  const navigate = useNavigate();
  const { stepper } = useAccessSharingFlowContext();
  const { step } = stepper;

  function handleBack() {
    navigate('/access');
  }

  if (!dummyEquipmentData.length) {
    return <NoMachinesEmptyState onClose={() => {}} />;
  }

  switch (step) {
    case ACCESS_SHARING_EQUIPMENT_STEPS.EQUIPMENT_SELECTION:
      return (
        <EquipmentSelectionStepController
          equipment={dummyEquipmentData}
          onBack={handleBack}
        />
      );
    case ACCESS_SHARING_EQUIPMENT_STEPS.ACCESS_SHARING:
      return <AccessSharingStepController />;
    case ACCESS_SHARING_EQUIPMENT_STEPS.OVERVIEW:
      return (
        <EquipmentsSharingOverviewStepController
          equipment={dummyEquipmentData}
          onBack={handleBack}
        />
      );
    default:
      return null;
  }
}

function UnsavedChangesWarning() {
  const {
    isSubmitted,
    isEditing,
    selectedUsers,
    selectedEquipmentIds,
    stepper,
  } = useAccessSharingFlowContext();
  const { step } = stepper;

  const isDirty = selectedUsers.size > 0 || selectedEquipmentIds.size > 0;
  const isStepWithPrompt = step === ACCESS_SHARING_EQUIPMENT_STEPS.OVERVIEW;

  const { showPrompt, confirmNavigation, cancelNavigation } =
    useNavigationPrompt(
      isDirty && (isStepWithPrompt || isEditing) && !isSubmitted,
    );

  return (
    <ConfirmNavigateAwayDialog
      open={showPrompt}
      onConfirm={confirmNavigation}
      onClose={cancelNavigation}
    />
  );
}
