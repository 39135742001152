import { getLocale } from 'locales/utils';

const getUrl = (locale, urls) => {
  const pieces = locale.split('-');
  return urls[locale] || urls[pieces[1]] || urls['US'];
};

const privacyPolicy = {};

privacyPolicy['US'] =
  'https://www.kubotausa.com/privacy-policy/kusa-online-privacy-policy';
privacyPolicy['CA'] = 'https://www.kubota.ca/privacy-policy';
privacyPolicy['en-CA'] = privacyPolicy['CA'];
privacyPolicy['fr-CA'] = 'https://www.kubota.ca/fr/privacy-policy';

export const getPrivacyPolicyUrl = locale => {
  return getUrl(locale, privacyPolicy);
};

const termsAndConditions = {};

termsAndConditions['US'] =
  'https://www.kubotausa.com/terms-of-use/kusa-terms-of-use';
termsAndConditions['CA'] = 'https://kubota.ca/terms-conditions';
termsAndConditions['en-CA'] = termsAndConditions['CA'];
termsAndConditions['fr-CA'] = 'https://kubota.ca/fr/terms-conditions';

export const gettermsAndConditionsUrl = locale => {
  return getUrl(locale, termsAndConditions);
};

const contactUsUrls = {
  US: 'https://kubotaservice.my.site.com/public/contactform',
  CA: 'https://app.smartsheet.com/b/form/a6e3eaf309c74aa7a4dc5375cc603baa',
  'en-CA': 'https://app.smartsheet.com/b/form/a6e3eaf309c74aa7a4dc5375cc603baa',
  'fr-CA': 'https://app.smartsheet.com/b/form/328a4ceeeae54eae862e96f2e180e7ac',
};

export const getContactUsUrl = () => {
  const locale = getLocale();
  return getUrl(locale, contactUsUrls);
};
