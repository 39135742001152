import { BaseButton } from 'app/components/buttons/BaseButton';
import { EditIcon } from 'app/components/icons';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IEquipmentEntry } from 'types/IEquipmentEntry';
import './EquipmentAccessSharingOverviewSection.less';
import clsx from 'clsx';
import { EquipmentAccessSharingOverviewCard } from './components/EquipmentAccessSharingOverviewCard';

interface Props {
  equipment: IEquipmentEntry[];
  className?: string;
  isLoading?: boolean;
  onEdit?: () => void;
}

export default function EquipmentAccessSharingOverviewSection({
  equipment,
  className,
  isLoading,
  onEdit,
}: Props) {
  const { t } = useTranslation();

  const columns = useMemo(() => {
    if (equipment.length >= 3) {
      return 3;
    } else if (equipment.length === 2) {
      return 2;
    }

    return 1;
  }, [equipment]);

  return (
    <section
      className={clsx('equipment-access-sharing-overview-section', className)}
    >
      <div className="equipment-access-sharing-overview-section__header">
        <h3 className="equipment-access-sharing-overview-section__title">
          {t('equipment')}
        </h3>

        <BaseButton onClick={onEdit} disabled={isLoading}>
          <EditIcon width={20} height={20} />
          <b>{t('edit').toUpperCase()}</b>
        </BaseButton>
      </div>

      <div
        className={`
          equipment-access-sharing-overview-section__cards 
          equipment-access-sharing-overview-section__cards_columns-${columns}
          `}
      >
        {equipment.map(equipment => {
          return (
            <EquipmentAccessSharingOverviewCard
              key={equipment.id}
              equipment={equipment}
            />
          );
        })}
      </div>
    </section>
  );
}
