import React from 'react';
import './CountDisplay.less';
import clsx from 'clsx';

interface Props {
  className?: string;
  badgeContent: number;
}

export default function CountDisplay({ className, badgeContent }: Props) {
  return (
    <span className={clsx('count-display', className)}>{badgeContent}</span>
  );
}
