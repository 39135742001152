import React from 'react';
import './CounterBadge.less';
import { CountDisplay } from 'app/components/badges/CountDisplay';

interface Props {
  badgeContent: number;
  text: string;
}

export default function CounterBadge({ badgeContent, text }: Props) {
  return (
    <span className="counter-badge">
      <span className="counter-badge__text">{text}</span>
      <CountDisplay badgeContent={badgeContent} />
    </span>
  );
}
