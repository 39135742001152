import { Dialog } from '@progress/kendo-react-dialogs';
import { EquipmentSelect } from 'app/components/access-sharing';
import { ErrorModal } from 'app/components/ErrorModal';
import ill_warning from 'assets/images/ill_warning.svg';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IEquipmentEntry } from 'types/IEquipmentEntry';
import './EquipmentSelectionView.less';
import { SubmitButton } from 'app/components/form-fields';
import {
  BackActionContentLayout,
  CardContentLayout,
} from 'app/components/layouts';

interface Props {
  equipment: IEquipmentEntry[];
  selectedEquipmentIds: Set<string>;
  setSelectedEquipmentIds: (ids: Set<string>) => void;
  onBack: () => void;
  onNextStep: () => void;
}

export default function EquipmentSelectionView({
  equipment,
  selectedEquipmentIds,
  setSelectedEquipmentIds,
  onBack,
  onNextStep,
}: Props) {
  const { t } = useTranslation();
  const [
    isSelectAtLeastOneItemDialogOpen,
    setIsSelectAtLeastOneItemDialogOpen,
  ] = useState(false);

  function handleNextStep() {
    if (!selectedEquipmentIds.size) {
      setIsSelectAtLeastOneItemDialogOpen(true);
      return;
    }

    onNextStep();
  }

  return (
    <>
      <BackActionContentLayout onBack={onBack}>
        <CardContentLayout
          title={t('select_equipment_title')}
          subtitle={t('select_equipment_subtitle')}
        >
          <EquipmentSelect
            equipment={equipment}
            selectedEquipmentIds={selectedEquipmentIds}
            className="equipment-selection-view__equipment-select"
            setSelectedEquipmentIds={setSelectedEquipmentIds}
          />

          <SubmitButton onClick={handleNextStep}>
            <div className="equipment-selection-view__continue-btn-content">
              {t('continue_text')}

              {selectedEquipmentIds.size > 0 && (
                <span className="equipment-selection-view__equipment-selected-text">
                  {t('no_equipment_selected', {
                    no: selectedEquipmentIds.size,
                  })}
                </span>
              )}
            </div>
          </SubmitButton>
        </CardContentLayout>
      </BackActionContentLayout>

      {/* TODO move into a separate component */}
      {isSelectAtLeastOneItemDialogOpen && (
        <Dialog className="modal-center modal-error modal-remove-item modal-fulltext">
          <ErrorModal
            icon={ill_warning}
            title={t('select_at_least_1_item')}
            class="select-items-modal"
            onClose={() => {
              setIsSelectAtLeastOneItemDialogOpen(false);
            }}
          />
        </Dialog>
      )}
    </>
  );
}
