import { Avatar } from 'app/components/Avatar';
import {
  SharedUserAccess,
  SharedUserAccessCard,
} from 'app/components/access-sharing/cards';
import { IconButton } from 'app/components/buttons';
import { EditIcon, TrashIcon } from 'app/components/icons';
import { BaseDataGrid, DataGridColumn } from 'app/components/tables';
import useHasAccessSharingPermissions from 'app/hooks/useHasAccessSharingPermissions';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AccessSharingRole } from 'types/entities/AccessSharingRole';
import './AccessSharedWith.less';
import clsx from 'clsx';
import { useIsMobile } from 'app/hooks/useIsMobile';
import { ActionsListItem } from 'app/components/menu';

interface Props {
  className?: string;
  role: AccessSharingRole;
  userAccess: SharedUserAccess[];
  expiredUserAccess: SharedUserAccess[];
  onChangeAccess: (userAccess: SharedUserAccess) => void;
  onRevokeAccess: (userAccess: SharedUserAccess) => void;
}

export default function AccessSharedWith({
  className,
  role,
  userAccess,
  expiredUserAccess,
  onChangeAccess,
  onRevokeAccess,
}: Props) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const hasAccess = useHasAccessSharingPermissions(role);

  const generateCardMenuActions = useCallback(
    (data: SharedUserAccess): ActionsListItem[] => [
      {
        label: t('access_sharing.role_based_actions.change_permissions'),
        exclude: !hasAccess('changeUserAccess'),
        action: () => {
          onChangeAccess(data);
        },
      },
      {
        label: t('access_sharing.role_based_actions.revoke_access'),
        action: () => {
          onRevokeAccess(data);
        },
      },
    ],
    [hasAccess],
  );

  const userAccessColumns = useMemo<DataGridColumn<SharedUserAccess>[]>(
    () => [
      {
        field: 'name',
        headerName: t('user'),
        flex: 3,
        renderCell: row => (
          <div className="access-shared-with__user-cell">
            <Avatar name={row.name} />
            <div className="access-shared-with__user-info">
              <b className="access-shared-with__username">{row.name}</b>
              <span className="access-shared-with__email">{row.email}</span>
            </div>
          </div>
        ),
      },
      {
        field: 'role',
        headerName: t('role'),
        renderCell: ({ role }) => t(role.toLowerCase()),
        flex: 1,
      },
      {
        field: 'startTime',
        headerName: t('start_time'),
        renderCell: () => (
          <span className="access-shared-with__date-cell">
            <span>{'March 25, 2024'}</span>
            <span>{'09:30 AM'}</span>
          </span>
        ),
        flex: 2,
      },
      {
        field: 'endTime',
        headerName: t('end_time'),
        renderCell: () => (
          <span className="access-shared-with__date-cell">
            <span>{'March 25, 2024'}</span>
            <span>{'09:30 AM'}</span>
          </span>
        ),
        flex: 2,
      },
      {
        field: 'actions',
        headerName: '',
        renderCell: row => (
          <div className="access-shared-with__table-actions-content">
            {hasAccess('changeUserAccess') && (
              <IconButton unstyled onClick={() => onChangeAccess(row)}>
                <EditIcon width={20} height={20} />
              </IconButton>
            )}

            <IconButton unstyled onClick={() => onRevokeAccess(row)}>
              <TrashIcon width={24} height={24} />
            </IconButton>
          </div>
        ),
      },
    ],
    [hasAccess, onChangeAccess, t],
  );

  const expiredUserAccessColumns = useMemo<DataGridColumn<SharedUserAccess>[]>(
    () => [
      {
        field: 'name',
        headerName: t('access_sharing.machine_details.expired_access'),
        flex: 3,
        renderCell: row => (
          <div className="access-shared-with__user-cell access-shared-with__expired-access-table-cell">
            <Avatar name={row.name} />
            <div className="access-shared-with__user-info">
              <b className="access-shared-with__username">{row.name}</b>
              <span className="access-shared-with__email">{row.email}</span>
            </div>
          </div>
        ),
      },
      {
        field: 'role',
        headerName: '',
        flex: 1,
        renderCell: ({ role }) => (
          <span className="access-shared-with__expired-access-table-cell">
            {t(role.toLowerCase())}
          </span>
        ),
      },
      {
        field: 'startTime',
        headerName: '',
        flex: 2,
        renderCell: row => (
          <span className="access-shared-with__expired-access-table-cell access-shared-with__date-cell">
            <span>{'March 25, 2024'}</span>
            <span>{'09:30 AM'}</span>
          </span>
        ),
      },
      {
        field: 'endTime',
        headerName: '',
        flex: 2,
        renderCell: row => (
          <span className="access-shared-with__expired-access-table-cell access-shared-with__date-cell">
            <span>{'March 25, 2024'}</span>
            <span>{'09:30 AM'}</span>
          </span>
        ),
      },
      {
        field: 'actions',
        headerName: '',
        renderCell: row => (
          <div className="access-shared-with__table-actions-content">
            {hasAccess('changeUserAccess') && (
              <IconButton unstyled onClick={() => onChangeAccess(row)}>
                <EditIcon width={20} height={20} />
              </IconButton>
            )}

            <IconButton unstyled onClick={() => onRevokeAccess(row)}>
              <TrashIcon width={24} height={24} />
            </IconButton>
          </div>
        ),
      },
    ],
    [hasAccess, onChangeAccess, t],
  );

  function renderCards() {
    return (
      <>
        {userAccess.map(data => (
          <SharedUserAccessCard
            key={data.id}
            userAccess={data}
            actions={generateCardMenuActions(data)}
          />
        ))}

        {!!expiredUserAccess.length && (
          <span className="access-shared-with__expired-access-label">
            {t('access_sharing.machine_details.expired_access')}
          </span>
        )}

        {expiredUserAccess.map(data => (
          <SharedUserAccessCard
            key={data.id}
            userAccess={data}
            isExpired
            actions={generateCardMenuActions(data)}
          />
        ))}
      </>
    );
  }

  function renderTables() {
    return (
      <>
        <BaseDataGrid columns={userAccessColumns} rows={userAccess} />

        {!!expiredUserAccess.length && (
          <BaseDataGrid
            className="access-shared-with__expired-access-table"
            columns={expiredUserAccessColumns}
            rows={expiredUserAccess}
          />
        )}
      </>
    );
  }

  return (
    <div className={clsx('access-shared-with', className)}>
      {isMobile ? renderCards() : renderTables()}
    </div>
  );
}
