import useStepper from 'app/hooks/useStepper';
import React, { createContext, useContext, useState } from 'react';
import { IAccessSharingUserData } from 'types/IAccessSharingUser';
import { Context, AccessSharingFlowContextProps } from './types';

const AccessSharingFlowContext = createContext<Context>({} as Context);

export function AccessSharingFlowProvider({
  children,
  startStep,
  stepCount,
}: AccessSharingFlowContextProps) {
  const stepper = useStepper({
    stepCount,
    startStep,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isFleetCreated, setIsFleetCreated] = useState(false);
  const [selectedFleetId, setSelectedFleetId] = useState<string>();
  const [selectedFleetName, setSelectedFleetName] = useState<string>();
  const [selectedEquipmentIds, setSelectedEquipmentIds] = useState(
    new Set<string>(),
  );
  const [selectedUsers, setSelectedUsers] = useState(
    new Map<string, IAccessSharingUserData>(),
  );
  const [editableUserEmail, setEditableUserEmail] = useState<
    string | undefined
  >();

  // TODO all these fields can be replaced with RHF
  const value = {
    stepper,
    isEditing,
    isSubmitted,
    isFleetCreated,
    selectedFleetId,
    selectedFleetName,
    selectedEquipmentIds,
    selectedUsers,
    editableUserEmail,
    setIsEditing,
    setIsSubmitted,
    setIsFleetCreated,
    setSelectedFleetId,
    setSelectedFleetName,
    setSelectedEquipmentIds,
    setSelectedUsers,
    setEditableUserEmail,
  };

  return (
    <AccessSharingFlowContext.Provider value={value}>
      {children}
    </AccessSharingFlowContext.Provider>
  );
}

const useAccessSharingFlowContext = () => useContext(AccessSharingFlowContext);

export default useAccessSharingFlowContext;
