import { Dialog } from '@progress/kendo-react-dialogs';
import { Success } from 'app/components/Notifications';
import { NotificationContext } from 'app/components/ReactContexts/notificationContext';
import { SubmitButton } from 'app/components/form-fields';
import { BackActionDialogContentLayout } from 'app/components/layouts';
import useClickOutside from 'app/hooks/useClickOutside';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import './EditFleetDialog.less';
import { EquipmentSelect } from 'app/components/access-sharing/EquipmentSelect';
import { dummyEquipmentData } from 'app/constants/access-sharing';
import { useState } from 'react';
import ill_warning from 'assets/images/ill_warning.svg';
import { ErrorModal } from 'app/components/ErrorModal';

interface Props {
  onClose: () => void;
}

export default function EditFleetDialog({ onClose }: Props) {
  const { t } = useTranslation();
  // TODO needs to be replaces with useTryMutate when API is ready
  const { addNotification } = React.useContext(NotificationContext);

  // Note: there's a point in creating a wrapper around the Dialog and adding an onClickOutside prop
  useClickOutside(onClose);

  const originalFleetEquipmentIds = [dummyEquipmentData[0].id];

  const [selectedEquipmentIds, setSelectedEquipmentIds] = useState(
    new Set(originalFleetEquipmentIds),
  );

  const [
    isSelectAtLeastOneItemDialogOpen,
    setIsSelectAtLeastOneItemDialogOpen,
  ] = useState(false);

  function handleSave() {
    if (!selectedEquipmentIds.size) {
      setIsSelectAtLeastOneItemDialogOpen(true);
      return;
    }

    addNotification(
      <Success
        text={t('access_sharing.notifications.fleet_successfully_updated')}
      />,
      6000,
    );
    onClose();
  }

  return (
    <>
      <Dialog className="modal-container">
        <BackActionDialogContentLayout
          className="edit-fleet-dialog"
          onBack={onClose}
        >
          <h2 className="edit-fleet-dialog__title">
            {t('access_sharing.dialogs.edit_fleet.title')}
          </h2>

          <p className="edit-fleet-dialog__subtitle">
            {t('access_sharing.dialogs.edit_fleet.subtitle')}
          </p>

          <EquipmentSelect
            equipment={dummyEquipmentData}
            selectedEquipmentIds={selectedEquipmentIds}
            className="edit-fleet-dialog__equipment-select"
            setSelectedEquipmentIds={setSelectedEquipmentIds}
          />

          <SubmitButton onClick={handleSave}>
            <div className="edit-fleet-dialog__continue-btn-content">
              {t('save')}

              {selectedEquipmentIds.size > 0 && (
                <span className="edit-fleet-dialog__equipment-selected-text">
                  {t('no_equipment_selected', {
                    no: selectedEquipmentIds.size,
                  })}
                </span>
              )}
            </div>
          </SubmitButton>
        </BackActionDialogContentLayout>
      </Dialog>

      {/* TODO move into a separate component */}
      {isSelectAtLeastOneItemDialogOpen && (
        <Dialog className="modal-center modal-error modal-remove-item modal-fulltext">
          <ErrorModal
            icon={ill_warning}
            title={t('select_at_least_1_item')}
            class="select-items-modal"
            onClose={() => {
              setIsSelectAtLeastOneItemDialogOpen(false);
            }}
          />
        </Dialog>
      )}
    </>
  );
}
