import { Card } from 'app/components/Card';
import { getDefaultThumbnail } from 'app/pages/Equipment/utils';
import EvEquipmentIcon from 'assets/images/ic_equipment_ev.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IEquipmentEntry } from 'types/IEquipmentEntry';
import './EquipmentAccessSharingOverviewCard.less';

interface Props {
  equipment: IEquipmentEntry;
}

export default function EquipmentAccessSharingOverviewCard({
  equipment,
}: Props) {
  const { t } = useTranslation();

  const isElectric = !!equipment.telematics?.electric;

  const defaultThumbnail = getDefaultThumbnail(
    equipment.type,
    equipment.subcategory,
  );
  const modelIconUrl = equipment.modelIconUrl || defaultThumbnail;
  const equipmentTitle = equipment.nickName || equipment.model;

  const handleImageError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>,
  ) => {
    event.currentTarget.onerror = null;
    event.currentTarget.src = defaultThumbnail;
  };

  return (
    <Card className="equipment-access-sharing-overview-card">
      <img
        src={modelIconUrl}
        alt="Equipment"
        className="equipment-access-sharing-overview-card__image"
        onError={handleImageError}
      />

      <div className="equipment-access-sharing-overview-card__equipment_info_block">
        <div className="equipment-access-sharing-overview-card__header">
          {isElectric && <img src={EvEquipmentIcon} alt="Electric model" />}

          <span
            className="equipment-access-sharing-overview-card__name"
            title={equipmentTitle}
          >
            {equipmentTitle}
          </span>
        </div>

        <div className="equipment-access-sharing-overview-card__details">
          <span className="equipment-access-sharing-overview-card__details-label">
            {t('equipment_model')}
          </span>
          <span className="equipment-access-sharing-overview-card__details-value">
            {equipment.model}
          </span>

          <span className="equipment-access-sharing-overview-card__details-label">
            {equipment.identifierType === 'Pin'
              ? t('equipment_pin')
              : t('equipment_serial')}
          </span>
          <span
            className="equipment-access-sharing-overview-card__details-value"
            title={equipment.pinOrSerial}
          >
            {equipment.pinOrSerial}
          </span>
        </div>
      </div>
    </Card>
  );
}
