import { Card } from '@progress/kendo-react-layout';
import { BaseButton } from 'app/components/buttons/BaseButton';
import { ActionsListItem, ActionsMenu } from 'app/components/menu';
import useHasAccessSharingPermissions from 'app/hooks/useHasAccessSharingPermissions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import capitalizeFirstLetter from 'utils/text/capitalizeFirstLetter';

interface ISharedFleetsRow {
  fleet: any;
  isMobile: boolean;
}

function SharedFleetsRow({ fleet, isMobile }: ISharedFleetsRow) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const hasAccess = useHasAccessSharingPermissions(fleet.role);

  const actions = useMemo<ActionsListItem[]>(() => {
    return [
      {
        label: t('access_sharing.role_based_actions.add_new_user'),
        action: () => navigate(`/access/shared-fleet/${fleet.id}/add-new-user`),
        exclude: !hasAccess('addNewUser'),
      },
      {
        label: t('access_sharing.role_based_actions.edit_fleet'),
        action: () => {},
        exclude: !hasAccess('editFleet'),
      },
      {
        label: t('access_sharing.role_based_actions.edit_fleet_name'),
        action: () => {},
        exclude: !hasAccess('editFleetName'),
      },
      {
        label: t('access_sharing.role_based_actions.delete_fleet'),
        action: () => {},
        exclude: !hasAccess('deleteFleet'),
      },
      // {
      //   label: t('access_sharing.role_based_actions.view_logs'),
      //   action: () => {},
      //   exclude: !hasAccess('viewLogs'),
      // },
      {
        label: t('access_sharing.role_based_actions.revoke_my_access'),
        action: () => {},
        exclude: !hasAccess('revokeOwnAccess'),
      },
    ];
  }, [hasAccess, t]);

  function handleOpenDetailsPage() {
    navigate('/access/shared-fleet/' + fleet.id);
  }

  return (
    <>
      {!isMobile ? (
        <BaseButton
          className="access-sharing-table-row"
          onClick={handleOpenDetailsPage}
        >
          <div>
            <span className="equipment-list-nickname">{fleet.name}</span>
            <span>
              {t('number_of_equipment', {
                numberOfEquipment: fleet.pieces,
              })}
            </span>
          </div>
          <div className="pending-details">
            {t(fleet.role.toLowerCase()).toUpperCase()}
          </div>
          <div className="pending-details">{fleet.owner}</div>
          <div className="pending-details">{fleet.email}</div>
          <div className="pending-details">
            {t('shared_with_users', { users: fleet.sharedWithUsers })}
          </div>

          <ActionsMenu actions={actions} />
        </BaseButton>
      ) : (
        <div
          className="access-sharing-table-card cursor-pointer"
          onClick={handleOpenDetailsPage}
        >
          <div className="row">
            <div className="col-xxs-12">
              <span className="equipment-list-nickname">{fleet.name}</span>
              <span className="number-of-eq-info">
                {t('number_of_equipment', {
                  numberOfEquipment: fleet.pieces,
                })}
              </span>
            </div>
          </div>
          <div className="equipment-list-details">
            <div className="row-4">
              <div className="col-xxs-12">
                <Card className="access-sharing-equipment-details">
                  <div>
                    <span className="bolded-info">{t('owner')}</span>
                    <span className="vertical-line"></span>
                    <span className="bolded-info">{fleet.owner} (Me)</span>
                  </div>
                  <div>{fleet.email}</div>
                </Card>
              </div>
            </div>
            <div className="row-4">
              <div className="col-xxs-6">
                <Card className="access-sharing-equipment-details">
                  <div className="bolded-info">{t('my_access')}</div>
                  <div className="colored-info">
                    {capitalizeFirstLetter(t(fleet.role.toLowerCase()))}
                  </div>
                </Card>
              </div>
              <div className="col-xxs-6">
                <Card className="access-sharing-equipment-details">
                  <div className="bolded-info">{t('shared_with')}</div>
                  <div>
                    {t('shared_with_users', { users: fleet.sharedWithUsers })}
                  </div>
                </Card>
              </div>
            </div>

            <div className="more-details-button">
              <ActionsMenu actions={actions} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SharedFleetsRow;
