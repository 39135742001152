import { getDefaultThumbnail } from 'app/pages/Equipment/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IEquipmentEntry } from 'types/IEquipmentEntry';
import './EquipmentInfo.less';
import clsx from 'clsx';

interface Props {
  className?: string;
  equipment: IEquipmentEntry;
}

export default function EquipmentInfo({ className, equipment }: Props) {
  const { t } = useTranslation();

  const defaultThumbnail = getDefaultThumbnail(
    equipment.type,
    equipment.subcategory,
  );
  const modelIconUrl = equipment.modelIconUrl || defaultThumbnail;
  const equipmentTitle = equipment.nickName || equipment.model;

  const handleImageError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>,
  ) => {
    event.currentTarget.onerror = null;
    event.currentTarget.src = defaultThumbnail;
  };

  return (
    <div className={clsx('equipment-info', className)}>
      <img
        src={modelIconUrl}
        alt="Equipment"
        className="equipment-info__image"
        onError={handleImageError}
      />

      <div className="equipment-info__content">
        <span className="equipment-info__header" title={equipmentTitle}>
          {equipmentTitle}
        </span>

        <div className="equipment-info__details">
          <span className="equipment-info__details-label">
            {t('equipment_model')}
          </span>
          <span className="equipment-info__details-value">
            {equipment.model}
          </span>

          <span className="equipment-info__details-label">
            {equipment.identifierType === 'Pin'
              ? t('equipment_pin')
              : t('equipment_serial')}
          </span>
          <span
            className="equipment-info__details-value"
            title={equipment.pinOrSerial}
          >
            {equipment.pinOrSerial}
          </span>
        </div>
      </div>
    </div>
  );
}
