import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface IConfirmAccessPeriodSettingsDialog {
  onCancel: Function;
  onContinue: Function;
}

export function ConfirmAccessPeriodSettingsDialog(
  props: IConfirmAccessPeriodSettingsDialog,
) {
  const { t } = useTranslation();
  return (
    <Dialog className="modal-center modal-error modal-remove-item modal-fulltext period-settings-dialog">
      <h3>{t('confirm_access_period_settings_title')}</h3>
      <p
        dangerouslySetInnerHTML={{
          __html: t('confirm_access_period_settings_subtitle'),
        }}
      ></p>
      <GridLayout
        cols={[{ width: '50%' }, { width: '50%' }]}
        style={{ gap: '12px' }}
      >
        <GridLayoutItem>
          <Button
            themeColor="dark"
            className="action-btn"
            onClick={() => {
              props.onCancel();
            }}
          >
            {t('cancel')}
          </Button>
        </GridLayoutItem>
        <GridLayoutItem>
          <Button
            themeColor="primary"
            className="action-btn"
            onClick={() => {
              props.onContinue();
            }}
          >
            {t('continue_text')}
          </Button>
        </GridLayoutItem>
      </GridLayout>
    </Dialog>
  );
}
