import React, { useMemo, useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import './FleetDetailsPage.less';
import { BaseTabs, TabPanel } from 'app/components/Tabs';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FleetDetailsTabs } from './constants';
import {
  dummyEquipmentData,
  dummyFleetsData,
  dummyUserAccess,
} from 'app/constants/access-sharing';
import { BackActionSplitCardContentLayout } from 'app/components/layouts';
import {
  MachineOwnerCard,
  SharedUserAccess,
} from 'app/components/access-sharing/cards';
import useHasAccessSharingPermissions from 'app/hooks/useHasAccessSharingPermissions';
import FleetImage from 'assets/images/ill_fleet_fleet-details.png';
import { ActionsListItem, ActionsMenu } from 'app/components/menu';
import { AddIcon } from 'app/components/icons';
import assert from 'assert';
import { AccessSharingRole } from 'types/entities/AccessSharingRole';
import { AccessSharedWith } from 'app/components/access-sharing';
import { IEquipmentEntry } from 'types/IEquipmentEntry';
import { EquipmentInfo } from 'app/components/access-sharing';
import { DeleteDialog } from 'app/components/dialogs';
import useTryMutate from 'app/hooks/useTryMutate';
import {
  ChangeUserFleetPermissionsDialog,
  EditFleetDialog,
  EditFleetNameDialog,
} from 'app/components/access-sharing/dialogs';
import { ShareAccessEquipmentGuideProvider } from 'app/hooks/access-sharing';

export default function FleetDetailsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const fleet: any | undefined = useMemo(
    () => dummyFleetsData.find(fleet => fleet.id === id),
    [id],
  );

  const equipment: IEquipmentEntry[] = useMemo(
    () => dummyEquipmentData.slice(0, 3),
    [id],
  );

  const hasAccess = useHasAccessSharingPermissions(fleet?.role);

  const [currentTab, setCurrentTab] = useState<
    keyof typeof FleetDetailsTabs | string
  >(FleetDetailsTabs.EQUIPMENT);

  // More appropriate entity name will be set when API is ready.
  const [selectedUserAccessToChange, setSelectedUserAccessToChange] = useState<
    SharedUserAccess | undefined
  >();
  const isChangeUserPermissionsDialogOpen = !!selectedUserAccessToChange;

  const [isDeleteFleetDialogOpen, setIsDeleteFleetDialogOpen] = useState(false);

  const [isEditFleetNameDialogOpen, setIsEditFleetNameDialogOpen] =
    useState(false);

  const [isEditFleetDialogOpen, setIsEditFleetDialogOpen] = useState(false);

  const [isRevokeAllUserAccessDialogOpen, setIsRevokeAllUserAccessDialogOpen] =
    useState(false);

  const [selectedUserAccessToRevoke, setSelectedUserAccessToRevoke] = useState<
    SharedUserAccess | undefined
  >();
  const isRevokeUserAccessDialogOpen = !!selectedUserAccessToRevoke;

  // for testing purposes
  const userAccess = useMemo<SharedUserAccess[]>(() => {
    assert(fleet.role);

    switch (fleet.role) {
      case AccessSharingRole.Viewer:
        return [dummyUserAccess[2]];
      case AccessSharingRole.Editor:
        return [dummyUserAccess[1]];
      case AccessSharingRole.Admin:
      case AccessSharingRole.Owner:
      default:
        return [dummyUserAccess[2], dummyUserAccess[1], dummyUserAccess[0]];
    }
  }, [fleet.role]);

  // for testing purposes
  const expiredUserAccess = useMemo<SharedUserAccess[]>(() => {
    assert(fleet.role);

    switch (fleet.role) {
      case AccessSharingRole.Viewer:
        return [];
      case AccessSharingRole.Editor:
        return [];
      case AccessSharingRole.Admin:
      case AccessSharingRole.Owner:
      default:
        return [dummyUserAccess[3], dummyUserAccess[4]];
    }
  }, [fleet.role]);

  const tabs = useMemo(
    () => [
      {
        label: t('equipment'),
        badgeContent: equipment.length,
        value: FleetDetailsTabs.EQUIPMENT,
      },
      {
        label: t('shared_with'),
        badgeContent: userAccess.length + expiredUserAccess.length,
        value: FleetDetailsTabs.SHARED_WITH,
      },
    ],
    [t],
  );

  const headerActions = useMemo<ActionsListItem[]>(() => {
    return [
      {
        label: t('access_sharing.role_based_actions.edit_fleet_name'),
        action: () => setIsEditFleetNameDialogOpen(true),
        exclude: !hasAccess('editFleetName'),
      },
      {
        label: t('access_sharing.role_based_actions.delete_fleet'),
        action: () => setIsDeleteFleetDialogOpen(true),
        exclude: !hasAccess('deleteFleet'),
      },
    ];
  }, [hasAccess, t]);

  const tabsActions = useMemo<ActionsListItem[]>(() => {
    return [
      {
        label: t('access_sharing.role_based_actions.remove_all_users'),
        action: () => setIsRevokeAllUserAccessDialogOpen(true),
        exclude: !hasAccess('revokeAllUsersAccess'),
      },
    ];
  }, [hasAccess, t]);

  function handleBack() {
    navigate('/access');
  }

  const handleChangeFleetName = useTryMutate(
    (name: string) => {
      return Promise.resolve(1);
    },
    {
      onComplete: (res, err, arg, api) => {
        api.successNotification(
          t('access_sharing.notifications.fleet_name_successfully_updated'),
        );
        setIsEditFleetNameDialogOpen(false);
      },
    },
  );

  const handleDeleteFleet = useTryMutate(
    () => {
      return Promise.resolve(1);
    },
    {
      onComplete: (res, err, arg, api) => {
        api.successNotification(
          t('access_sharing.notifications.fleet_successfully_deleted'),
        );
        setIsDeleteFleetDialogOpen(false);
        handleBack();
      },
    },
  );

  const handleRevokeAllAccess = useTryMutate(
    () => {
      return Promise.resolve(1);
    },
    {
      onComplete: (res, err, arg, api) => {
        api.successSnackbarNotification(
          t('access_sharing.snackbars.revoke-all-access'),
        );
        setIsRevokeAllUserAccessDialogOpen(false);
        handleBack();
      },
    },
  );

  const handleRevokeAccess = useTryMutate(
    () => {
      return Promise.resolve(1);
    },
    {
      onComplete: (res, err, arg, api) => {
        api.successSnackbarNotification(
          t('access_sharing.snackbars.revoke-access', {
            email: selectedUserAccessToRevoke?.email,
          }),
        );
        setSelectedUserAccessToRevoke(undefined);
      },
    },
  );

  function renderTabActinButtons() {
    const isEquipmentTabActive = currentTab === FleetDetailsTabs.EQUIPMENT;
    const isSharedWithTabActive = currentTab === FleetDetailsTabs.SHARED_WITH;

    const hasAccessToAddNewUser = hasAccess('addNewUser');
    const hasAccessToEditFleet = hasAccess('editFleet');
    const hasAccessToRevokeAllAccess = hasAccess('revokeAllUsersAccess');
    const hasAccessToAnything =
      hasAccessToAddNewUser ||
      hasAccessToEditFleet ||
      hasAccessToRevokeAllAccess;

    if (!hasAccessToAnything) {
      return null;
    }

    return (
      <div className="fleet-details-page__user-actions">
        {isEquipmentTabActive && hasAccessToEditFleet && (
          <Button
            className="fleet-details-page__action-btn"
            themeColor="secondary"
            onClick={() => setIsEditFleetDialogOpen(true)}
          >
            {t('access_sharing.role_based_actions.edit_fleet')}
          </Button>
        )}

        {isSharedWithTabActive && hasAccessToAddNewUser && (
          <>
            <Button
              className="fleet-details-page__action-btn fleet-details-page__add-new-user-btn"
              themeColor="secondary"
              onClick={() =>
                navigate(`/access/shared-fleet/${fleet.id}/add-new-user`)
              }
            >
              <AddIcon strokeWidth={2} />
              {t('add_user')}
            </Button>

            <ActionsMenu actions={tabsActions} />
          </>
        )}
      </div>
    );
  }

  // TODO Request a design
  if (!fleet) {
    return <h1>Fleet Not Found</h1>;
  }

  return (
    <>
      <BackActionSplitCardContentLayout
        className="fleet-details-page"
        backActionProps={{ onBack: handleBack }}
        renderHeader={() => (
          <div className="fleet-details-page__header">
            <div className="fleet-details-page__info-and-picture-wrapper">
              <div className="fleet-details-page__info-wrapper">
                <div className="fleet-details-page__title">
                  <h1 className="fleet-details-page__nickname">{fleet.name}</h1>
                  <ActionsMenu actions={headerActions} />
                </div>
              </div>

              <div className="fleet-details-page__picture">
                <img src={FleetImage} alt="Fleet" />
              </div>
            </div>

            <MachineOwnerCard
              name="Alexander Turner"
              email="andrew@example.com"
              className="fleet-details-page__owner-card"
            />

            <div className="fleet-details-page__tabs-and-actions-wrapper">
              <BaseTabs
                className="fleet-details-page__tabs"
                tabs={tabs}
                value={currentTab}
                onChange={setCurrentTab}
              />

              {renderTabActinButtons()}
            </div>
          </div>
        )}
        renderContent={() => (
          <div className="fleet-details-page__content">
            <TabPanel
              value={FleetDetailsTabs.EQUIPMENT}
              currentTab={currentTab}
            >
              {equipment.map(equipment => (
                <EquipmentInfo
                  key={equipment.id}
                  className="fleet-details-page__equipment-info-card"
                  equipment={equipment}
                />
              ))}
            </TabPanel>

            <TabPanel
              value={FleetDetailsTabs.SHARED_WITH}
              currentTab={currentTab}
            >
              <AccessSharedWith
                className="fleet-details-page__shared-with"
                role={fleet.role}
                userAccess={userAccess}
                expiredUserAccess={expiredUserAccess}
                onChangeAccess={setSelectedUserAccessToChange}
                onRevokeAccess={setSelectedUserAccessToRevoke}
              />
            </TabPanel>
          </div>
        )}
      />

      {isEditFleetNameDialogOpen && (
        <EditFleetNameDialog
          initialValue={fleet.name}
          onChange={handleChangeFleetName}
          onClose={() => setIsEditFleetNameDialogOpen(false)}
        />
      )}

      <ShareAccessEquipmentGuideProvider>
        {isEditFleetDialogOpen && (
          <EditFleetDialog onClose={() => setIsEditFleetDialogOpen(false)} />
        )}
      </ShareAccessEquipmentGuideProvider>

      {isChangeUserPermissionsDialogOpen && (
        <ChangeUserFleetPermissionsDialog
          fleet={fleet}
          userAccess={selectedUserAccessToChange}
          onClose={() => setSelectedUserAccessToChange(undefined)}
        />
      )}

      <DeleteDialog
        open={isDeleteFleetDialogOpen}
        title={t('access_sharing.dialogs.delete_fleet.title', {
          name: fleet.name,
        })}
        confirmationLabel={t(
          'access_sharing.dialogs.delete_fleet.actions.delete',
        )}
        onClose={() => setIsDeleteFleetDialogOpen(false)}
        onConfirm={handleDeleteFleet}
      />

      <DeleteDialog
        open={isRevokeUserAccessDialogOpen}
        title={t('revoke_access_confirmation', {
          email: selectedUserAccessToRevoke?.email,
          machineName: fleet.name,
        })}
        onClose={() => setSelectedUserAccessToRevoke(undefined)}
        onConfirm={handleRevokeAccess}
      />

      <DeleteDialog
        open={isRevokeAllUserAccessDialogOpen}
        title={t('revoke_access_confirmation_all', {
          machineName: fleet.name,
        })}
        onClose={() => setIsRevokeAllUserAccessDialogOpen(false)}
        onConfirm={handleRevokeAllAccess}
      />
    </>
  );
}
