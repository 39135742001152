import { NotificationGroup } from '@progress/kendo-react-notification';
import { Notification as SnackBarNotification } from '@progress/kendo-react-notification';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { HashRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { ContentAndImage } from './components/layouts/ContentAndImage';
import { MenuAndContent } from './components/layouts/MenuAndContent';
import { SubmenuAndContent } from './components/layouts/SubmenuAndContent';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { EquipmentListContext } from './components/ReactContexts/equipmentListContext';
import { NotificationContext } from './components/ReactContexts/notificationContext';
import { GlobalErrorContext } from './components/ReactContexts/globalErrorContext';
import { AboutPage } from './pages/AboutPage';
import { CreateAccountPage } from './pages/CreateAccountPage';
import { DealerList } from './pages/Dealers/DealerList';
import { DealerMap } from './pages/Dealers/DealerMap';
import { EquipmentContentIndex } from './pages/Equipment/ContentIndex';
import { EquipmentContent } from './pages/Equipment/EquipmentContent';
import { EquipmentList } from './pages/Equipment/EquipmentList';
import { EquipmentNotLoaded } from './pages/Equipment/EquipmentNotLoaded';
import { KeepDataClean } from './pages/Equipment/KeepDataClean';
import { ForgotPasswordPage } from './pages/ForgotPasswordPage';
import { LegalTermsPage } from './pages/LegalTermsPage';
import { LoginPage } from './pages/LogInPage';
import { LoginHeader } from './pages/LogInPage/LogInHeader';
import { MaintenanceSchedules } from './pages/MaintenanceSchedulePage';
import { ResourcesPage } from './pages/ResourcesPage';
import { GuidesPage } from './pages/ResourcesPage/GuidesPage';
import { ManualViewer } from './pages/ResourcesPage/ManualViewer';
import { ModelPage } from './pages/ResourcesPage/ModelPage';
import { ResourceSubcategoriesPage } from './pages/ResourcesPage/ResourceSubcategoriesPage';
import { ResourcesFaultCodesPage } from './pages/ResourcesPage/ResourcesFaultCodesPage';
import { VideoResourcesPage } from './pages/ResourcesPage/VideoResourcesPage';
import { SettingsPage } from './pages/SettingsPage';
import { TrackMaintanenceContent } from './pages/TrackMaintenencePage';
import { Geofence } from './pages/Equipment/Geofence';
import ForgotPassImgWebp from 'assets/images/account/img_auth_forgotpass.webp';
import ForgotPassImgJpg from 'assets/images/account/img_auth_forgotpass.jpg';
import LoginImgWebp from 'assets/images/account/img_auth_login.webp';
import LoginImgJpg from 'assets/images/account/img_auth_login.jpg';
import RegisterImgWebp from 'assets/images/account/img_auth_register.webp';
import RegisterImgJpg from 'assets/images/account/img_auth_register.jpg';
import { GeofencesMenu } from './pages/Equipment/Geofence/GeofencesMenu';
import ScrollToTop from './widgets/ScrollToTop';
import {
  firebaseConfig,
  getFirebaseToken,
  isFirebaseSupported,
  onMessageListener,
} from 'firebaseConfig/firebase';
import { PushNotificationContext } from './components/ReactContexts/pushNotificationContext';
import { PrivateRoute } from './components/PrivateRoute';
import { RequireGuestRoute } from './components/RequireGuestRoute';
import { ajax } from 'ajax/ajax';
import { MaintenanceDetails } from './pages/MaintenanceSchedulePage/Details';
import { GlobalErrorModal } from './components/GlobalErrorModal';
import AccessSharingPage from './pages/AccessSharingPage';
import {
  ShareEquipmentPage,
  CreateFleetPage,
  ShareFleetPage,
  MachineDetailsPage,
  MachineAccessAssignmentPage,
  FleetAccessAssignmentPage,
} from 'app/pages/AccessSharingPage/pages';
import useFlag from 'app/hooks/useFlag';
import { FeatureName } from 'types/entities/MobileSettings';
import { useMobileSettingsContext } from 'context';
import { FullscreenLoader } from 'app/components/FullscreenLoader';
import { FleetDetailsPage } from 'app/pages/AccessSharingPage/pages/FleetDetailsPage';

const generateUUID = () => {
  // Public Domain/MIT
  var d = new Date().getTime(); //Timestamp
  var d2 =
    (typeof performance !== 'undefined' &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export function App() {
  const { i18n } = useTranslation();

  const { isLoading: isMobileSettingsLoading } = useMobileSettingsContext();
  const isAccessSharingPageEnabled = useFlag(FeatureName.ACCESS_SHARING);

  const isAppLoading = isMobileSettingsLoading;

  const { notifications, snackbarNotification } =
    React.useContext(NotificationContext);
  const { equipmentListData } = React.useContext(EquipmentListContext);
  const { addPushNotification, pushNotifications } = React.useContext(
    PushNotificationContext,
  );
  const fcmCall = React.useRef<boolean>(false);
  const deleteCall = React.useRef<boolean>(false);

  const { showErrorModal, closeErrorModal } =
    React.useContext(GlobalErrorContext);

  onMessageListener()
    .then(payload => {
      if (payload.data?.messageId) {
        addPushNotification(payload.data?.messageId);
      }
    })
    .catch(err => console.log('failed: ', err));

  React.useEffect(() => {
    (async () => {
      if (!(await isFirebaseSupported())) {
        console.log(
          'Firebase push notification feature is not supported on this browser.',
        );
        return;
      }

      const deviceId = window.localStorage.getItem('kubota_deviceId');
      if (Notification?.permission === 'granted') {
        navigator.serviceWorker.onmessage = event => {
          if (event.data.messageType === 'notification-clicked') {
            if (event?.data?.data?.messageId) {
              addPushNotification(event?.data?.data?.messageId);
            }
          }
        };
      } else if (deviceId && !deleteCall.current && ajax.isLoggedIn()) {
        deleteCall.current = true;
        ajax
          .delete(`${ajax.appBaseUrl}/notification/fcm-token/${deviceId}`)
          .then(result => {
            if (result.status == 200) {
              console.log('deleted succesfully');
              window.localStorage.removeItem('kubota_deviceId');
            }
          });
      }
    })();
  }, []);

  const emptyListDefault = (comp: JSX.Element) => {
    return !!equipmentListData?.equipmentList?.length ? (
      comp
    ) : (
      <MenuAndContent>
        <EquipmentNotLoaded />
      </MenuAndContent>
    );
  };

  const notLoadedList = (comp: JSX.Element) => {
    return equipmentListData?.equipmentList &&
      equipmentListData?.equipmentList?.length >= 0 ? (
      comp
    ) : (
      <MenuAndContent>
        <EquipmentNotLoaded />
      </MenuAndContent>
    );
  };

  React.useEffect(() => {
    (async () => {
      if (!(await isFirebaseSupported())) {
        console.log(
          'Firebase push notification feature is not supported on this browser.',
        );
        return;
      }

      if (
        (window.location.hash.includes('askNotificationPermission') ||
          Notification?.permission === 'granted') &&
        !fcmCall.current &&
        !window.localStorage.getItem('kubota_deviceId') &&
        ajax.isLoggedIn()
      ) {
        fcmCall.current = true;
        const swReg = await navigator.serviceWorker.register(
          `/firebase-messaging-sw.js?${new URLSearchParams({
            ...firebaseConfig,
          } as any).toString()}`,
        );

        const deviceId = `web${generateUUID()}`;
        const fcmReg = async token => {
          await ajax
            .post(
              `${ajax.appBaseUrl}/notification/fcm-token?${new URLSearchParams({
                token,
                platform: 'web',
                deviceId,
              } as any).toString()}`,
            )
            .then(res => {
              window.localStorage.setItem('kubota_deviceId', deviceId);
            })
            .catch(ex => console.log(ex));
        };
        await getFirebaseToken(swReg, fcmReg);
      }
    })();
  });

  React.useEffect(() => {
    if (window.location.hash.startsWith('#/equipment/?messageId=')) {
      const messageId = window.location.hash.slice(-36);
      if (!pushNotifications.includes(messageId)) {
        addPushNotification(messageId);
      }
    }
  }, [pushNotifications]);

  if (isAppLoading) {
    return <FullscreenLoader />;
  }

  return (
    <HashRouter>
      <ScrollToTop />
      <Helmet
        titleTemplate="%s - myKubota"
        defaultTitle="myKubota"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="muKubota web application" />
      </Helmet>

      <NotificationGroup
        className="alert"
        style={{
          top: '20px',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        {notifications?.map(notification => notification.element)}
      </NotificationGroup>

      {snackbarNotification && (
        <SnackBarNotification className="alert snackbar">
          {snackbarNotification}
        </SnackBarNotification>
      )}

      {showErrorModal && <GlobalErrorModal onClose={closeErrorModal} />}

      <KeepDataClean />

      <Routes>
        <Route
          path="/forgotPassword"
          element={
            <RequireGuestRoute>
              <ContentAndImage
                header={<LoginHeader />}
                webpImage={ForgotPassImgWebp}
                jpgImage={ForgotPassImgJpg}
              >
                <ForgotPasswordPage />
              </ContentAndImage>
            </RequireGuestRoute>
          }
        />
        <Route
          path="/createAccount"
          element={
            <RequireGuestRoute>
              <ContentAndImage
                header={<LoginHeader />}
                webpImage={RegisterImgWebp}
                jpgImage={RegisterImgJpg}
              >
                <CreateAccountPage />
              </ContentAndImage>
            </RequireGuestRoute>
          }
        />
        <Route
          path="/login"
          element={
            <RequireGuestRoute>
              <ContentAndImage
                header={<LoginHeader />}
                webpImage={LoginImgWebp}
                jpgImage={LoginImgJpg}
              >
                <LoginPage />
              </ContentAndImage>
            </RequireGuestRoute>
          }
        />

        <Route
          path="/equipment/geofences"
          element={
            <PrivateRoute>
              {emptyListDefault(
                <SubmenuAndContent submenu={<GeofencesMenu />}>
                  <Geofence />
                </SubmenuAndContent>,
              )}
            </PrivateRoute>
          }
        />

        <Route
          path="/equipment/"
          element={
            <PrivateRoute>
              {emptyListDefault(
                <SubmenuAndContent submenu={<EquipmentList />}>
                  <EquipmentContentIndex>
                    <EquipmentContent />
                  </EquipmentContentIndex>
                </SubmenuAndContent>,
              )}
            </PrivateRoute>
          }
        />

        <Route
          path="/equipment/:id/"
          element={
            <PrivateRoute>
              {emptyListDefault(
                <SubmenuAndContent submenu={<EquipmentList />}>
                  <EquipmentContentIndex>
                    <EquipmentContent />
                  </EquipmentContentIndex>
                </SubmenuAndContent>,
              )}
            </PrivateRoute>
          }
        />

        <Route
          path="/equipment/:id/guides"
          element={
            <PrivateRoute>
              {emptyListDefault(
                <SubmenuAndContent submenu={<EquipmentList />}>
                  <EquipmentContentIndex>
                    <GuidesPage />
                  </EquipmentContentIndex>
                </SubmenuAndContent>,
              )}
            </PrivateRoute>
          }
        />

        <Route
          path="/equipment/:id/faultCodes"
          element={
            <PrivateRoute>
              {emptyListDefault(
                <SubmenuAndContent submenu={<EquipmentList />}>
                  <EquipmentContentIndex>
                    <ResourcesFaultCodesPage />
                  </EquipmentContentIndex>
                </SubmenuAndContent>,
              )}
            </PrivateRoute>
          }
        />

        <Route
          path="/equipment/:id/manual"
          element={
            <PrivateRoute>
              {emptyListDefault(
                <SubmenuAndContent submenu={<EquipmentList />}>
                  <EquipmentContentIndex>
                    <ManualViewer />
                  </EquipmentContentIndex>
                </SubmenuAndContent>,
              )}
            </PrivateRoute>
          }
        />

        <Route
          path="/equipment/:id/videoResources"
          element={
            <PrivateRoute>
              {emptyListDefault(
                <SubmenuAndContent submenu={<EquipmentList />}>
                  <EquipmentContentIndex>
                    <VideoResourcesPage />
                  </EquipmentContentIndex>
                </SubmenuAndContent>,
              )}
            </PrivateRoute>
          }
        />

        <Route
          path="/equipment/:id/trackMaintenance"
          element={
            <PrivateRoute>
              {emptyListDefault(
                <SubmenuAndContent submenu={<EquipmentList />}>
                  <EquipmentContentIndex>
                    <TrackMaintanenceContent />
                  </EquipmentContentIndex>
                </SubmenuAndContent>,
              )}
            </PrivateRoute>
          }
        />

        <Route
          path="/equipment/:id/maintenanceSchedule"
          element={
            <PrivateRoute>
              {emptyListDefault(
                <SubmenuAndContent submenu={<EquipmentList />}>
                  <EquipmentContentIndex>
                    <MaintenanceSchedules />
                  </EquipmentContentIndex>
                </SubmenuAndContent>,
              )}
            </PrivateRoute>
          }
        />

        <Route
          path="/equipment/:id/maintenanceSchedule/:interval"
          element={
            <PrivateRoute>
              {emptyListDefault(
                <SubmenuAndContent submenu={<EquipmentList />}>
                  <EquipmentContentIndex>
                    <MaintenanceDetails />
                  </EquipmentContentIndex>
                </SubmenuAndContent>,
              )}
            </PrivateRoute>
          }
        />

        <Route
          path="/resources"
          element={
            <PrivateRoute>
              <MenuAndContent>
                <ResourcesPage />
              </MenuAndContent>
            </PrivateRoute>
          }
        />

        <Route
          path="/resources/:id/"
          element={
            <PrivateRoute>
              <MenuAndContent>
                <ResourceSubcategoriesPage />
              </MenuAndContent>
            </PrivateRoute>
          }
        />

        <Route
          path="/models/:model/"
          element={
            <PrivateRoute>
              <MenuAndContent>
                <ModelPage />
              </MenuAndContent>
            </PrivateRoute>
          }
        />

        <Route
          path="/models/:model/maintenanceSchedule"
          element={
            <PrivateRoute>
              <MenuAndContent>
                <MaintenanceSchedules />
              </MenuAndContent>
            </PrivateRoute>
          }
        />

        <Route
          path="/models/:model/maintenanceSchedule/:interval"
          element={
            <PrivateRoute>
              <MenuAndContent>
                <MaintenanceDetails />
              </MenuAndContent>
            </PrivateRoute>
          }
        />

        <Route
          path="/faultCodes/:model/"
          element={
            <PrivateRoute>
              <MenuAndContent>
                <ResourcesFaultCodesPage />
              </MenuAndContent>
            </PrivateRoute>
          }
        />

        <Route
          path="/manual/:model/"
          element={
            <PrivateRoute>
              <MenuAndContent>
                <ManualViewer />
              </MenuAndContent>
            </PrivateRoute>
          }
        />

        <Route
          path="/videoResources/:model/"
          element={
            <PrivateRoute>
              <MenuAndContent>
                <VideoResourcesPage />
              </MenuAndContent>
            </PrivateRoute>
          }
        />

        <Route
          path="/guides/:model/"
          element={
            <PrivateRoute>
              <MenuAndContent>
                <GuidesPage />
              </MenuAndContent>
            </PrivateRoute>
          }
        />

        <Route
          path="/dealers"
          element={
            <PrivateRoute>
              <SubmenuAndContent submenu={<DealerList />}>
                <DealerMap />
              </SubmenuAndContent>
            </PrivateRoute>
          }
        />

        <Route
          path="/about"
          element={
            <PrivateRoute>
              <MenuAndContent>
                <AboutPage />
              </MenuAndContent>
            </PrivateRoute>
          }
        />

        <Route
          path="/legalTerms"
          element={
            <PrivateRoute>
              <MenuAndContent>
                <LegalTermsPage />
              </MenuAndContent>
            </PrivateRoute>
          }
        />

        <Route
          path="/settings"
          element={
            <PrivateRoute>
              <MenuAndContent>
                <SettingsPage />
              </MenuAndContent>
            </PrivateRoute>
          }
        />

        {isAccessSharingPageEnabled && (
          <>
            <Route
              path="/access"
              element={
                <PrivateRoute>
                  {notLoadedList(
                    <MenuAndContent>
                      <Outlet />
                    </MenuAndContent>,
                  )}
                </PrivateRoute>
              }
            >
              <Route index element={<AccessSharingPage />} />
              <Route path="details/:id" element={<MachineDetailsPage />} />
              <Route path="share-equipment" element={<ShareEquipmentPage />} />
              <Route path="create-fleet" element={<CreateFleetPage />} />
              <Route path="share-fleet" element={<ShareFleetPage />} />

              <Route
                path="shared-equipment/:id/add-new-user"
                element={<MachineAccessAssignmentPage />}
              />

              <Route
                path="shared-fleet/:id/add-new-user"
                element={<FleetAccessAssignmentPage />}
              />
            </Route>

            {/* It's placed separately due to a different mobile layout approach. Can be optimized later */}
            <Route
              path="/access"
              element={
                <PrivateRoute>
                  {notLoadedList(
                    <MenuAndContent useTheEntireSpace>
                      <Outlet />
                    </MenuAndContent>,
                  )}
                </PrivateRoute>
              }
            >
              <Route
                path="shared-equipment/:id"
                element={<MachineDetailsPage />}
              />

              <Route path="shared-fleet/:id" element={<FleetDetailsPage />} />
            </Route>
          </>
        )}

        <Route path="/forgotPassword" element={<ForgotPasswordPage />} />
        <Route path="/createAccount" element={<CreateAccountPage />} />
        <Route path="/" element={<Navigate to="/equipment" />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </HashRouter>
  );
}
