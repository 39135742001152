import { Button } from '@progress/kendo-react-buttons';
import { LayoutContext } from 'app/components/ReactContexts/layoutContext';
import { AccessSharedWith } from 'app/components/access-sharing';
import {
  MachineOwnerCard,
  SharedUserAccess,
} from 'app/components/access-sharing/cards';
import { ChangeUserPermissionsDialog } from 'app/components/access-sharing/dialogs';
import { CounterBadge } from 'app/components/badges';
import { DeleteDialog } from 'app/components/dialogs';
import { AddIcon } from 'app/components/icons';
import { BackActionSplitCardContentLayout } from 'app/components/layouts';
import { ActionsListItem, ActionsMenu } from 'app/components/menu';
import {
  dummyEquipmentData,
  dummyUserAccess,
} from 'app/constants/access-sharing';
import useHasAccessSharingPermissions from 'app/hooks/useHasAccessSharingPermissions';
import useTryMutate from 'app/hooks/useTryMutate';
import { getDefaultThumbnail } from 'app/pages/Equipment/utils';
import assert from 'assert';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { IEquipmentEntry } from 'types/IEquipmentEntry';
import { AccessSharingRole } from 'types/entities/AccessSharingRole';
import './MachineDetailsPage.less';

export default function MachineDetailsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { setClassNames, removeClassNames } = React.useContext(LayoutContext);

  const { id } = useParams();

  // More appropriate entity name will be set when API is ready.
  const [selectedUserAccessToChange, setSelectedUserAccessToChange] = useState<
    SharedUserAccess | undefined
  >();
  const isChangeUserPermissionsDialogOpen = !!selectedUserAccessToChange;

  const [isRevokeAllUserAccessDialogOpen, setIsRevokeAllUserAccessDialogOpen] =
    useState(false);

  const [selectedUserAccessToRevoke, setSelectedUserAccessToRevoke] = useState<
    SharedUserAccess | undefined
  >();
  const isRevokeUserAccessDialogOpen = !!selectedUserAccessToRevoke;

  const equipment: IEquipmentEntry | undefined = useMemo(
    () => dummyEquipmentData.find(equipment => equipment.id === id),
    [id],
  );

  const hasAccess = useHasAccessSharingPermissions(equipment?.role);

  const mainMenuActions = useMemo<ActionsListItem[]>(() => {
    return [
      {
        label: t('access_sharing.role_based_actions.remove_all_users'),
        action: () => setIsRevokeAllUserAccessDialogOpen(true),
      },
    ];
  }, [hasAccess, t, setIsRevokeAllUserAccessDialogOpen]);

  // for testing purposes
  const userAccess = useMemo<SharedUserAccess[]>(() => {
    assert(equipment?.role);

    switch (equipment.role) {
      case AccessSharingRole.Viewer:
        return [dummyUserAccess[2]];
      case AccessSharingRole.Editor:
        return [dummyUserAccess[1]];
      case AccessSharingRole.Admin:
      case AccessSharingRole.Owner:
        return [dummyUserAccess[2], dummyUserAccess[1], dummyUserAccess[0]];
    }
  }, [equipment?.role]);

  // for testing purposes
  const expiredUserAccess = useMemo<SharedUserAccess[]>(() => {
    assert(equipment?.role);

    switch (equipment.role) {
      case AccessSharingRole.Viewer:
        return [];
      case AccessSharingRole.Editor:
        return [];
      case AccessSharingRole.Admin:
      case AccessSharingRole.Owner:
        return [dummyUserAccess[3], dummyUserAccess[4]];
    }
  }, [equipment?.role]);

  React.useEffect(() => {
    const classNameIndex = setClassNames('layout-grey');
    return () => {
      removeClassNames(classNameIndex);
    };
  }, []);

  const handleRevokeAllAccess = useTryMutate(
    () => {
      return Promise.resolve(1);
    },
    {
      onComplete: (res, err, arg, api) => {
        api.successSnackbarNotification(
          t('access_sharing.snackbars.revoke-all-access'),
        );
        setIsRevokeAllUserAccessDialogOpen(false);
        handleBack();
      },
    },
  );

  const handleRevokeAccess = useTryMutate(
    () => {
      return Promise.resolve(1);
    },
    {
      onComplete: (res, err, arg, api) => {
        api.successSnackbarNotification(
          t('access_sharing.snackbars.revoke-access', {
            email: selectedUserAccessToRevoke?.email,
          }),
        );
        setSelectedUserAccessToRevoke(undefined);
      },
    },
  );

  function handleBack() {
    navigate('/access');
  }

  // TODO Request a design
  if (!equipment) {
    return <h1>Equipment Not Found</h1>;
  }

  return (
    <>
      <BackActionSplitCardContentLayout
        backActionProps={{ onBack: handleBack }}
        renderHeader={() => (
          <div className="machine-details-page__header">
            <div className="machine-details-page__info-and-picture-wrapper">
              <div className="machine-details-page__info-wrapper">
                <h1 className="machine-details-page__nickname">
                  {equipment?.nickName || equipment?.model}
                </h1>

                <div className="machine-details-page__model">
                  <span className="machine-details-page__model-label">
                    {t('equipment_model')}
                  </span>
                  {equipment.model}
                  <span className="machine-details-page__model-label">
                    {t('equipment_serial')}
                  </span>
                  {equipment.serial}
                </div>
              </div>

              <div className="machine-details-page__picture">
                <img
                  src={
                    equipment?.modelIconUrl ||
                    getDefaultThumbnail(equipment?.type, equipment?.subcategory)
                  }
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = getDefaultThumbnail(
                      equipment?.type,
                      equipment?.subcategory,
                    );
                  }}
                  alt="Machine"
                />
              </div>
            </div>

            <MachineOwnerCard
              name="Alexander Turner"
              email="andrew@example.com"
              className="machine-details-page__owner-card"
            />
          </div>
        )}
        renderContent={() => (
          <div className="machine-details-page__content">
            <div className="machine-details-page__user-actions-wrapper">
              <CounterBadge badgeContent={1} text={t('shared_with')} />

              <div className="machine-details-page__user-actions">
                {hasAccess('addNewUser') && (
                  <Button
                    className="machine-details-page__add-new-user-btn"
                    themeColor="secondary"
                    onClick={() =>
                      navigate(
                        `/access/shared-equipment/${equipment.id}/add-new-user`,
                      )
                    }
                  >
                    <AddIcon strokeWidth={2} />
                    {t('add_user')}
                  </Button>
                )}

                {hasAccess('revokeAllUsersAccess') && (
                  <ActionsMenu actions={mainMenuActions} />
                )}
              </div>
            </div>

            <AccessSharedWith
              role={equipment.role!}
              userAccess={userAccess}
              expiredUserAccess={expiredUserAccess}
              onChangeAccess={setSelectedUserAccessToChange}
              onRevokeAccess={setSelectedUserAccessToRevoke}
            />
          </div>
        )}
      />

      {isChangeUserPermissionsDialogOpen && (
        <ChangeUserPermissionsDialog
          equipment={equipment}
          userAccess={selectedUserAccessToChange}
          onClose={() => setSelectedUserAccessToChange(undefined)}
        />
      )}

      <DeleteDialog
        open={isRevokeUserAccessDialogOpen}
        title={t('revoke_access_confirmation', {
          email: selectedUserAccessToRevoke?.email,
          machineName: equipment.nickName || equipment.model,
        })}
        onClose={() => setSelectedUserAccessToRevoke(undefined)}
        onConfirm={handleRevokeAccess}
      />

      <DeleteDialog
        open={isRevokeAllUserAccessDialogOpen}
        title={t('revoke_access_confirmation_all', {
          machineName: equipment.nickName || equipment.model,
        })}
        onClose={() => setIsRevokeAllUserAccessDialogOpen(false)}
        onConfirm={handleRevokeAllAccess}
      />
    </>
  );
}
