import {
  EquipmentAccessSharingOverviewSection,
  UserAccessSharingOverviewSection,
} from 'app/components/access-sharing';
import { CloseIcon } from 'app/components/icons';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IAccessSharingUserData } from 'types/IAccessSharingUser';
import { IEquipmentEntry } from 'types/IEquipmentEntry';
import './EquipmentsSharingOverviewView.less';
import { SubmitButton } from 'app/components/form-fields';
import {
  BackActionContentLayout,
  CardContentLayout,
} from 'app/components/layouts';

type Props = {
  equipment: IEquipmentEntry[];
  selectedEquipmentIds: Set<string>;
  selectedUsers: Map<string, IAccessSharingUserData>;
  isLoading: boolean;
  setSelectedUsers: (users: Map<string, IAccessSharingUserData>) => void;
  onEditEquipment: () => void;
  onEditUser: (email: string) => void;
  onAddUser: () => void;
  onBack: () => void;
  onSubmit: () => void;
};

export default function EquipmentsSharingOverviewView({
  equipment,
  selectedEquipmentIds,
  selectedUsers,
  isLoading,
  setSelectedUsers,
  onEditEquipment,
  onEditUser,
  onAddUser,
  onBack,
  onSubmit,
}: Props) {
  const { t } = useTranslation();

  const selectedEquipment = useMemo(() => {
    return Array.from(selectedEquipmentIds.values())
      .map(id => equipment.find(equipment => equipment.id === id))
      .filter((equipment): equipment is IEquipmentEntry => !!equipment);
  }, [equipment]);

  function handleDeleteUser(email: string) {
    const updatedUsers = new Map(selectedUsers);
    updatedUsers.delete(email);
    setSelectedUsers(updatedUsers);
  }

  return (
    <BackActionContentLayout
      isLoading={isLoading}
      backIcon={CloseIcon}
      onBack={onBack}
    >
      <CardContentLayout
        title={t('access_sharing_overview')}
        subtitle={t('information_correct_and_complete')}
      >
        <EquipmentAccessSharingOverviewSection
          equipment={selectedEquipment}
          className="equipments-sharing-overview-view__equipment-access-sharing-overview-section"
          isLoading={isLoading}
          onEdit={onEditEquipment}
        />

        <UserAccessSharingOverviewSection
          users={Array.from(selectedUsers.values())}
          className="equipments-sharing-overview-view__user-access-sharing-overview-section"
          isLoading={isLoading}
          onEdit={onEditUser}
          onDelete={handleDeleteUser}
          onAdd={onAddUser}
        />

        <SubmitButton disabled={isLoading} onClick={onSubmit}>
          {t('send_invitations')}
        </SubmitButton>
      </CardContentLayout>
    </BackActionContentLayout>
  );
}
