import { Dialog } from '@progress/kendo-react-dialogs';
import { SubmitButton, TextField } from 'app/components/form-fields';
import { BackActionDialogContentLayout } from 'app/components/layouts';
import useClickOutside from 'app/hooks/useClickOutside';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import './EditFleetNameDialog.less';

interface Props {
  initialValue: string;
  onChange: (value: string) => void;
  onClose: () => void;
}

export default function EditLastNameDialog({
  initialValue,
  onChange,
  onClose,
}: Props) {
  const { t } = useTranslation();

  // Note: there's a point in creating a wrapper around the Dialog and adding an onClickOutside prop
  useClickOutside(onClose);

  const [name, setName] = React.useState(initialValue);
  const [fleetNameError, setNameError] = React.useState('');
  const [isLoading] = React.useState(false);

  function validateName() {
    if (!name) {
      setNameError(
        t('access_sharing.dialogs.edit_fleet_name.field_is_required'),
      );
      return false;
    }

    return true;
  }

  const saveChangesButtonClicked = () => {
    if (!validateName()) {
      return;
    }

    onChange(name);
  };

  return (
    <Dialog className="modal-container">
      <BackActionDialogContentLayout
        className="edit-fleet-name-dialog"
        onBack={onClose}
      >
        <h2 className="edit-fleet-name-dialog__title">
          {t('access_sharing.dialogs.edit_fleet_name.label')}
        </h2>

        <TextField
          label={t('access_sharing.dialogs.edit_fleet_name.label')}
          required
          value={name}
          placeholder={t('access_sharing.dialogs.edit_fleet_name.placeholder')}
          onChange={event => {
            setName(event.target.value as string);
            setNameError('');
          }}
          disabled={isLoading}
          error={fleetNameError}
        />

        <SubmitButton
          onClick={() => {
            saveChangesButtonClicked();
          }}
        >
          {t('save')}
        </SubmitButton>
      </BackActionDialogContentLayout>
    </Dialog>
  );
}
