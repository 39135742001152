import { Dialog } from '@progress/kendo-react-dialogs';
import {
  Card,
  CardBody,
  CardHandle,
  CardHeader,
  CardSubtitle,
  CardTitle,
} from '@progress/kendo-react-layout';
import { ajax } from 'ajax/ajax';
import { ResetPasswordPage } from 'app/pages/ResetPasswordPage';
import ClickOutside from 'app/widgets/ClickOutside';
import {
  chevron_right as ChevronRight,
  ic_account_language as IconLanguage,
  ic_account_page as IconLegalTerms,
  ic_account_logout as IconLogout,
  ic_account_password as IconPassword,
  ic_account_settings as IconSettings,
  ic_account_support as IconSupport,
} from 'app/widgets/SVGs';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { chevron_down as DownArrow } from 'app/widgets/SVGs';
import _ from 'lodash';
import { Avatar } from '../Avatar';
import { EquipmentListContext } from '../ReactContexts/equipmentListContext';
import { GlobalErrorContext } from '../ReactContexts/globalErrorContext';
import { LayoutContext } from '../ReactContexts/layoutContext';
import { SignOutDialog } from './SignOutDialog';
import useFlag from 'app/hooks/useFlag';
import { FeatureName } from 'types/entities/MobileSettings';
import { getContactUsUrl } from 'utils/link-urls';

export function SingedInComponent() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const isSingleSignOnFeatureEnabled = useFlag(FeatureName.SINGLE_SIGN_ON);

  const [language, setLanguage] = React.useState('');
  const [isLanguageExpanded, setIsLanguageExpanded] = React.useState(false);
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [showResetPasswordDialog, setShowResetPasswordDialog] =
    React.useState<boolean>(false);

  const [showSignoutDialog, setShowSignoutDialog] = React.useState(false);
  const { classNames, removeSpecificClass } = React.useContext(LayoutContext);
  const { setEquipmentListData } = React.useContext(EquipmentListContext);
  const { addErrorModal } = React.useContext(GlobalErrorContext);

  const cardRef = React.useRef(null as null | CardHandle);

  var contactUsUrl = getContactUsUrl();

  const handleClick = () => {
    if (_.some(classNames, cls => cls.class === 'mobile-nav-shown')) {
      removeSpecificClass('mobile-nav-shown');
    }
  };

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (event.target.className === 'k-overlay') {
        event.stopPropagation();
        setShowResetPasswordDialog(false);
        setShowSignoutDialog(false);
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });

  const onNavigateClicked = (item: string) => {
    setIsExpanded(false);
    setIsLanguageExpanded(false);
    switch (item) {
      case 'setting':
        navigate('/settings');
        break;
      case 'password':
        setShowResetPasswordDialog(true);
        break;
      case 'language':
        setIsExpanded(true);
        setIsLanguageExpanded(() => !isLanguageExpanded);
        break;
      case 'report':
        window.open(contactUsUrl, '_blank');
        break;
      case 'legalTerms':
        navigate('/legalTerms');
        break;
      case 'signOut':
        setShowSignoutDialog(true);
    }
  };

  const changeLanguage = (selectedValue: string) => {
    const selectedLanguage = selectedValue;
    setLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem('language', selectedLanguage);
  };

  React.useEffect(() => {
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage != null) {
      setLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage);
    }
  }, []);

  const handleSignOut = async () => {
    const deviceId = window.localStorage.getItem('kubota_deviceId');
    if (deviceId) {
      await ajax
        .delete(`${ajax.appBaseUrl}/notification/fcm-token/${deviceId}`)
        .then(result => {
          if (result.status == 200) {
            console.log('deleted succesfully');
            window.localStorage.removeItem('kubota_deviceId');
          }
        })
        .catch(ex => {
          addErrorModal();
        });
    }
    ajax.logOut();
  };

  const handleLanguageClickOutside = event => {
    const targetNode = event.target as Node;
    if (cardRef.current && !cardRef.current.element?.contains(targetNode)) {
      setIsExpanded(false);
    }
    setIsLanguageExpanded(false);
  };

  return (
    <>
      {showResetPasswordDialog && (
        <Dialog className="modal-form">
          <ResetPasswordPage close={() => setShowResetPasswordDialog(false)} />
        </Dialog>
      )}
      {showSignoutDialog && (
        <Dialog className="modal-center modal-remove-item modal-fulltext">
          <SignOutDialog
            close={() => setShowSignoutDialog(false)}
            signOut={handleSignOut}
          />
        </Dialog>
      )}
      <ClickOutside
        onClickOutside={() => {
          setIsExpanded(false);
        }}
      >
        <>
          <span
            className="account-actions"
            onClick={() => {
              setIsExpanded(!isExpanded);
              handleClick();
            }}
          >
            <span className="username-text">
              {window.localStorage.getItem('kubota_user_name')}
            </span>
            <Avatar
              name={window.localStorage
                .getItem('kubota_user_name')
                ?.toUpperCase()}
            />
            <span className="avatar-down-arrow">
              <DownArrow />
            </span>
          </span>
          {isExpanded && (
            <Card
              className="top-card top-card-account-settings top-card-account-settings-logged-in"
              ref={cardRef}
            >
              <CardHeader>
                <CardTitle>{t('welcome_message')}</CardTitle>
                <CardSubtitle>
                  {window.localStorage.getItem('kubota_user_name')}
                </CardSubtitle>
              </CardHeader>
              <CardBody>
                <div>
                  <ul className="k-reset k-header k-menu k-menu-vertical">
                    <li className="k-item k-menu-item k-first">
                      <span
                        className="k-link k-menu-link"
                        onClick={() => {
                          onNavigateClicked('setting');
                        }}
                      >
                        <div>
                          <span>
                            <span className="link-icon">
                              <IconSettings />
                            </span>
                            <span className="link-text">
                              {isSingleSignOnFeatureEnabled
                                ? t('account_and_settings')
                                : t('profile_settings')}
                            </span>
                          </span>
                          <span className="link-arrow">
                            <ChevronRight />
                          </span>
                        </div>
                      </span>
                    </li>

                    <li className="k-item k-menu-item">
                      <span
                        className="k-link k-menu-link"
                        onClick={() => {
                          onNavigateClicked('password');
                        }}
                      >
                        <div>
                          <span>
                            <span className="link-icon">
                              <IconPassword />
                            </span>
                            <span className="link-text">
                              {t('change_password_preference_title')}
                            </span>
                          </span>
                          <span className="link-arrow">
                            <ChevronRight />
                          </span>
                        </div>
                      </span>
                    </li>

                    <li className="k-item k-menu-item">
                      <ClickOutside onClickOutside={handleLanguageClickOutside}>
                        <span
                          className="k-link k-menu-link"
                          onClick={() => {
                            onNavigateClicked('language');
                          }}
                        >
                          <div>
                            <span>
                              <span className="link-icon">
                                <IconLanguage />
                              </span>
                              <span className="link-text">
                                <div className="language-component language-menu-dropdown">
                                  <span>
                                    <span>{t('language')}</span>
                                    <span className="selected-language">
                                      {language.toLocaleUpperCase().slice(0, 2)}
                                    </span>
                                  </span>
                                  {isLanguageExpanded && (
                                    <Card className="top-card top-card-language-setting language-menu-dropdown-options">
                                      <div className="">
                                        <span
                                          onClick={() => {
                                            if (i18n.language !== 'en') {
                                              changeLanguage('en');
                                              setEquipmentListData(oldData => ({
                                                ...oldData,
                                                equipmentList: undefined,
                                                selectedEquipment: undefined,
                                                selectedGeofence: undefined,
                                              }));
                                            }
                                          }}
                                        >
                                          {t('language_EN')}
                                        </span>
                                        <span
                                          onClick={() => {
                                            if (i18n.language !== 'fr') {
                                              changeLanguage('fr');
                                              setEquipmentListData(oldData => ({
                                                ...oldData,
                                                equipmentList: undefined,
                                                selectedEquipment: undefined,
                                                selectedGeofence: undefined,
                                              }));
                                            }
                                          }}
                                        >
                                          {t('language_FR')}
                                        </span>
                                      </div>
                                    </Card>
                                  )}
                                </div>
                              </span>
                            </span>
                            <span className="link-arrow">
                              <ChevronRight />
                            </span>
                          </div>
                        </span>
                      </ClickOutside>
                    </li>

                    <li className="k-item k-menu-item">
                      <span
                        className="k-link k-menu-link"
                        onClick={() => {
                          onNavigateClicked('report');
                        }}
                      >
                        <div>
                          <span>
                            <span className="link-icon">
                              <IconSupport />
                            </span>
                            <span className="link-text">
                              {t('kubota_report_problem')}
                            </span>
                          </span>
                          <span className="link-arrow">
                            <ChevronRight />
                          </span>
                        </div>
                      </span>
                    </li>

                    <li className="k-item k-menu-item">
                      <span
                        className="k-link k-menu-link"
                        onClick={() => {
                          onNavigateClicked('legalTerms');
                        }}
                      >
                        <div>
                          <span>
                            <span className="link-icon">
                              <IconLegalTerms />
                            </span>
                            <span className="link-text">
                              {t('legal_terms_fragment_title')}
                            </span>
                          </span>
                          <span className="link-arrow">
                            <ChevronRight />
                          </span>
                        </div>
                      </span>
                    </li>

                    <li className="k-item k-menu-item k-last">
                      <span
                        className="k-link k-menu-link"
                        onClick={() => {
                          onNavigateClicked('signOut');
                        }}
                      >
                        <div>
                          <span>
                            <span className="link-icon">
                              <IconLogout />
                            </span>
                            <span className="link-text">
                              {t('sign_out_title')}
                            </span>
                          </span>
                          <span className="link-arrow">
                            <ChevronRight />
                          </span>
                        </div>
                      </span>
                    </li>
                  </ul>
                </div>
              </CardBody>
            </Card>
          )}
        </>
      </ClickOutside>
    </>
  );
}
