import React from 'react';
import { ShareAccessEquipmentGuideProvider } from 'app/hooks/access-sharing';
import { useAccessSharingFlowContext } from 'app/hooks/access-sharing';
import { ACCESS_SHARING_EQUIPMENT_STEPS } from '../constants';
import { IEquipmentEntry } from 'types/IEquipmentEntry';
import { EquipmentSelectionView } from 'app/components/access-sharing/flows';

interface Props {
  equipment: IEquipmentEntry[];
  onBack: () => void;
}

export default function EquipmentSelectionStepController({
  equipment,
  onBack,
}: Props) {
  const {
    stepper,
    isEditing,
    selectedEquipmentIds,
    setIsEditing,
    setSelectedEquipmentIds,
  } = useAccessSharingFlowContext();

  const { setStep } = stepper;

  function handlePrevStep() {
    if (isEditing) {
      setStep(ACCESS_SHARING_EQUIPMENT_STEPS.OVERVIEW);
      setIsEditing(false);
    } else {
      onBack();
    }
  }

  function handleNextStep() {
    if (isEditing) {
      setStep(ACCESS_SHARING_EQUIPMENT_STEPS.OVERVIEW);
      setIsEditing(false);
    } else {
      setStep(ACCESS_SHARING_EQUIPMENT_STEPS.ACCESS_SHARING);
    }
  }

  return (
    <ShareAccessEquipmentGuideProvider>
      <EquipmentSelectionView
        equipment={equipment}
        selectedEquipmentIds={selectedEquipmentIds}
        setSelectedEquipmentIds={setSelectedEquipmentIds}
        onBack={handlePrevStep}
        onNextStep={handleNextStep}
      />
    </ShareAccessEquipmentGuideProvider>
  );
}
