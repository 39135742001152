import { Card } from '@progress/kendo-react-layout';
import { useLoadScript } from '@react-google-maps/api';
import { librariesList } from 'app/common/librariesList';
import { EquipmentTelematicDescription } from 'app/components/EquipmentTelematicDescription';
import { EquipmentListContext } from 'app/components/ReactContexts/equipmentListContext';
import { LayoutContext } from 'app/components/ReactContexts/layoutContext';
import { SmartAttachmentModelDescription } from 'app/components/SmartAttachmentModelDescription';
import EquipmentLinkIcon from 'assets/images/ic_equipment_link.svg';
import _ from 'lodash';
import * as React from 'react';
import { useNavigate } from 'react-router';
import { useLocation, useSearchParams } from 'react-router-dom';
import { IEquipmentEntry } from 'types/IEquipmentEntry';
import { getDefaultThumbnail } from '../utils';
import { useMemo } from 'react';
import clsx from 'clsx';

interface IEquipmentListCard {
  index: number;
  equipment: IEquipmentEntry;
}

const ATTACHED_EQUIPMENT_ID_QUERY_PARAM = 'attachedEquipmentId';

export function EquipmentListCard(props: IEquipmentListCard) {
  const { equipmentListData: data, setEquipmentListData: setData } =
    React.useContext(EquipmentListContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const attachedEquipmentId = searchParams.get(
    ATTACHED_EQUIPMENT_ID_QUERY_PARAM,
  );

  const cardCoordinatesRef = React.useRef<{
    screenX: number;
    screenY: number;
    hasMoved: boolean;
  }>();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.GOOGLE_API_KEY as string,
    libraries: librariesList,
  });

  const [adress, setAdress] = React.useState('' as string | null);

  const { classNames, setClassNames } = React.useContext(LayoutContext);

  const handleClick = () => {
    if (!_.some(classNames, cls => cls.class === 'equipment-details-visible')) {
      setClassNames('equipment-details-visible');
    }
  };

  React.useEffect(() => {
    if (
      isLoaded &&
      props.equipment.telematics?.location?.latitude &&
      props.equipment.telematics?.location?.longitude
    ) {
      new google.maps.Geocoder()
        .geocode({
          location: {
            lat: props.equipment.telematics?.location.latitude,
            lng: props.equipment.telematics?.location.longitude,
          },
        })
        .then(result => {
          setAdress(result.results[0].formatted_address);
        })
        .catch(e => {
          setAdress(null);
        });
    }
    if (
      isLoaded &&
      (!props.equipment.telematics?.location?.latitude ||
        !props.equipment.telematics?.location?.longitude)
    ) {
      setAdress(null);
    }
  }, [isLoaded]);

  const [width, setWidth] = React.useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const cardRef = React.useRef<HTMLSpanElement | null>();

  React.useEffect(() => {
    if (data.openContent) {
      setClassNames('equipment-details-visible');
      setData(oldData => ({
        ...oldData,
        openContent: false,
      }));
    }
  }, [data.openContent]);

  React.useEffect(() => {
    if (data.selectedEquipment?.id === props.equipment.id) {
      cardRef?.current?.scrollIntoView();
      if (width > 1024) {
        if (
          !_.some(classNames, cls => cls.class === 'equipment-details-visible')
        ) {
          setClassNames('equipment-details-visible');
        }
      }
    }
  }, []);

  const smartAttachmentInfo = props.equipment.telematics?.smartAttachment
    ?.serial
    ? data.equipmentList?.find(
        s => s.serial === props.equipment.telematics?.smartAttachment?.serial,
      )
    : null;
  const smartAttachmentProps = {
    name: smartAttachmentInfo
      ? smartAttachmentInfo.nickName || smartAttachmentInfo.model
      : props.equipment.telematics?.smartAttachment?.model,
    model: props.equipment.telematics?.smartAttachment?.model,
    pin: props.equipment.telematics?.smartAttachment?.serial,
    imageUrl:
      smartAttachmentInfo?.modelIconUrl ||
      getDefaultThumbnail(
        smartAttachmentInfo?.type,
        smartAttachmentInfo?.subcategory,
      ),
  };

  const isSmartAttached = useMemo(
    () =>
      data.equipmentList?.some(
        item =>
          item.telematics?.smartAttachment?.serial === props.equipment.serial,
      ),
    [data.equipmentList],
  );

  return (
    <span
      ref={val => {
        cardRef.current = val;
      }}
      className={
        props.equipment.telematics?.smartAttachment
          ? 'equipment-list-intel-attachment'
          : ''
      }
    >
      <Card
        className={clsx('equipment-list-card', {
          'equipment-list-card-selected':
            props.equipment.id === data.selectedEquipment?.id &&
            !attachedEquipmentId,
        })}
        onMouseDown={e => {
          cardCoordinatesRef.current = {
            screenX: e.screenX,
            screenY: e.screenY,
            hasMoved: false,
          };
        }}
        onMouseMove={e => {
          if (!cardCoordinatesRef.current) {
            return;
          }
          if (
            Math.abs(e.screenX - cardCoordinatesRef.current.screenX) +
              Math.abs(e.screenY - cardCoordinatesRef.current.screenY) >
            10
          ) {
            cardCoordinatesRef.current = {
              hasMoved: true,
              screenX: -100,
              screenY: -100,
            };
          }
        }}
        onMouseUp={() => {
          if (!cardCoordinatesRef.current?.hasMoved) {
            setData(oldData => ({
              ...oldData,
              selectedEquipment: props.equipment,
              selectedIndex: props.index,
              geoAddress: adress,
            }));
            handleClick();

            const redirectUrl = `/equipment/${props.equipment.id}`;

            const currentPath = location.pathname;
            const searchParams = new URLSearchParams(location.search);

            if (
              currentPath !== redirectUrl ||
              searchParams.has(ATTACHED_EQUIPMENT_ID_QUERY_PARAM)
            ) {
              navigate(redirectUrl);
            }
          }
        }}
      >
        <EquipmentTelematicDescription
          equipment={props.equipment}
          isSmartAttached={isSmartAttached}
        />
      </Card>
      {props.equipment.telematics?.smartAttachment && (
        <Card
          className={clsx('equipment-list-card', {
            'equipment-list-card-selected':
              smartAttachmentInfo?.id === data.selectedEquipment?.id &&
              !!attachedEquipmentId,
            'equipment-list-card-without-shadow': !smartAttachmentInfo,
          })}
          onMouseDown={e => {
            cardCoordinatesRef.current = {
              screenX: e.screenX,
              screenY: e.screenY,
              hasMoved: false,
            };
          }}
          onMouseMove={e => {
            if (!cardCoordinatesRef.current) {
              return;
            }
            if (
              Math.abs(e.screenX - cardCoordinatesRef.current.screenX) +
                Math.abs(e.screenY - cardCoordinatesRef.current.screenY) >
              10
            ) {
              cardCoordinatesRef.current = {
                hasMoved: true,
                screenX: -100,
                screenY: -100,
              };
            }
          }}
          onMouseUp={() => {
            if (!cardCoordinatesRef.current?.hasMoved && smartAttachmentInfo) {
              setData(oldData => ({
                ...oldData,
                selectedEquipment: smartAttachmentInfo,
                selectedIndex: props.index,
              }));
              handleClick();

              const redirectUrl = `/equipment/${smartAttachmentInfo.id}`;
              const parentIdParam = `?${ATTACHED_EQUIPMENT_ID_QUERY_PARAM}=${props.equipment.id}`;

              const currentPath = location.pathname;
              const currentSearch = location.search;

              if (
                currentPath !== redirectUrl ||
                currentSearch !== parentIdParam
              ) {
                navigate(`${redirectUrl}${parentIdParam}`);
              }
            }
          }}
        >
          <div className="intel-info-link-icon">
            <img src={EquipmentLinkIcon} alt="Equipment Link Icon"></img>
          </div>
          <SmartAttachmentModelDescription equipment={smartAttachmentProps} />
        </Card>
      )}
    </span>
  );
}
