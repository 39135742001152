import React from 'react';
import './BaseTabs.less';

interface Props<T> {
  value: T;
  currentTab: T;
  children: React.ReactNode;
}

export default function TabPanel<T extends string>({
  value,
  currentTab,
  children,
}: Props<T>) {
  if (value != currentTab) {
    return null;
  }

  return <>{children}</>;
}
