import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { dummyFleetsData } from 'app/constants/access-sharing';
import useHasAccessSharingPermissions from 'app/hooks/useHasAccessSharingPermissions';
import { AccessConfiguration } from 'app/components/access-sharing';
import {
  BackActionContentLayout,
  CardContentLayout,
} from 'app/components/layouts';
import { useTranslation } from 'react-i18next';
import { SubmitButton, TextField } from 'app/components/form-fields';
import {
  IAccessSharingPeriodType,
  IAccessSharingPermissionType,
} from 'types/IAccessSharingUser';
import { emailRegex } from 'app/common/utils';
import { ConfirmAccessPeriodSettingsDialog } from 'app/pages/AccessSharingPage/SelectUser/ConfirmAccessPeriodSettingsDialog';
import useTryMutate from 'app/hooks/useTryMutate';

export default function FleetAccessAssignmentPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const fleet: any | undefined = useMemo(
    () => dummyFleetsData.find(fleet => fleet.id === id),
    [id],
  );
  const hasAccess = useHasAccessSharingPermissions(fleet?.role!);
  const canAddNewUser = hasAccess('addNewUser');

  const [emailError, setEmailError] = useState('');
  const [email, setEmail] = useState('');
  const [permission, setPermission] = useState(
    IAccessSharingPermissionType.Viewer,
  );
  const [period, setPeriod] = useState(IAccessSharingPeriodType.Continuous);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const [
    showConfirmAccessPeriodSettingsDialog,
    setShowConfirmAccessPeriodSettingsDialog,
  ] = useState(false);

  function handleConfirmAccessPeriodSettingsDialog() {
    setShowConfirmAccessPeriodSettingsDialog(false);
    handleSubmit({ currentPeriod: IAccessSharingPeriodType.Continuous });
  }

  const handleSubmit = useTryMutate(
    (options: { currentPeriod: IAccessSharingPeriodType }) => {
      const { currentPeriod } = options;
      const startYear = startDate?.getFullYear() as number;
      const startMonth = startDate?.getMonth() as number;
      const endYear = endDate?.getFullYear() as number;
      const endMonth = endDate?.getMonth() as number;
      const isStartDateSet = startYear && startMonth;
      const isEndDateSet = endYear && endMonth;

      if (
        (!isStartDateSet || !isEndDateSet) &&
        currentPeriod === IAccessSharingPeriodType.Temporary
      ) {
        setShowConfirmAccessPeriodSettingsDialog(true);
        return;
      }

      return Promise.resolve(1);
    },
    {
      onComplete: (res, err, arg, api) => {
        api.successNotification(
          t('access_sharing.notifications.invitation-successfully-sent'),
        );
        navigate(-1);
      },
    },
  );

  const validateEmail = () => {
    if (!email) {
      setEmailError(t('field_is_required'));
      return false;
    }

    if (!email.toLowerCase().match(emailRegex)) {
      setEmailError(t('incorrect_email_format'));
      return false;
    }

    return true;
  };

  // TODO Clarify behavior
  if (!fleet || !canAddNewUser) {
    navigate('/access');
  }

  return (
    <>
      <BackActionContentLayout onBack={() => navigate(-1)}>
        <CardContentLayout
          title={t('select_user_to_share_title')}
          subtitle={t('select_user_to_share_subtitle')}
        >
          <div className="access-sharing-view">
            <TextField
              label={t('email')}
              value={email}
              onChange={event => {
                setEmail(event.target.value as string);
                setEmailError('');
              }}
              required
              className="access-sharing-view__text-field"
              placeholder={t('what_is_their_email')}
              error={emailError}
            />

            <AccessConfiguration
              initialPermission={permission}
              initialPeriod={period}
              initialStartDate={startDate}
              initialEndDate={endDate}
              onChangePermission={setPermission}
              onChangePeriod={setPeriod}
              onChangeStartDate={setStartDate}
              onChangeEndDate={setEndDate}
            />
          </div>

          <SubmitButton
            onClick={() => {
              if (validateEmail()) {
                handleSubmit({ currentPeriod: period });
              }
            }}
          >
            {t('continue_text')}
          </SubmitButton>
        </CardContentLayout>
      </BackActionContentLayout>

      {/* TODO refactor this dialog */}
      {showConfirmAccessPeriodSettingsDialog && (
        <ConfirmAccessPeriodSettingsDialog
          onCancel={() => setShowConfirmAccessPeriodSettingsDialog(false)}
          onContinue={handleConfirmAccessPeriodSettingsDialog}
        />
      )}
    </>
  );
}
