import { AccessSharingView } from 'app/components/access-sharing/flows';
import { useAccessSharingFlowContext } from 'app/hooks/access-sharing';
import { ACCESS_SHARING_EQUIPMENT_STEPS } from '../constants';
import React from 'react';

export default function AccessSharingStepController() {
  const {
    stepper,
    isEditing,
    selectedUsers,
    editableUserEmail,
    setIsEditing,
    setSelectedUsers,
  } = useAccessSharingFlowContext();

  const { setStep } = stepper;

  function handlePrevStep() {
    if (isEditing) {
      setStep(ACCESS_SHARING_EQUIPMENT_STEPS.OVERVIEW);
      setIsEditing(false);
    } else {
      setStep(ACCESS_SHARING_EQUIPMENT_STEPS.EQUIPMENT_SELECTION);
    }
  }

  function handleNextStep() {
    setStep(ACCESS_SHARING_EQUIPMENT_STEPS.OVERVIEW);
  }

  return (
    <AccessSharingView
      selectedUsers={selectedUsers}
      editableUserEmail={editableUserEmail}
      setSelectedUsers={setSelectedUsers}
      onBack={handlePrevStep}
      onNextStep={handleNextStep}
    />
  );
}
