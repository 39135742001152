import { EquipmentService } from 'ajax/services';
import useTryMutate from 'app/hooks/useTryMutate';
import React, { useState } from 'react';
import { IEquipmentEntry } from 'types/IEquipmentEntry';
import { useAccessSharingFlowContext } from 'app/hooks/access-sharing';
import { ACCESS_SHARING_EQUIPMENT_STEPS } from '../constants';
import { EquipmentsSharingOverviewView } from 'app/components/access-sharing/flows';
import { useTranslation } from 'react-i18next';

type Props = {
  equipment: IEquipmentEntry[];
  onBack: () => void;
};

export default function EquipmentsSharingOverviewStepController({
  equipment,
  onBack,
}: Props) {
  const { t } = useTranslation();

  const {
    stepper,
    selectedUsers,
    selectedEquipmentIds,
    setIsEditing,
    setIsSubmitted,
    setSelectedUsers,
    setEditableUserEmail,
  } = useAccessSharingFlowContext();
  const { setStep } = stepper;
  const [isLoading, setIsLoading] = useState(false);

  function handleEditEquipment() {
    setStep(ACCESS_SHARING_EQUIPMENT_STEPS.EQUIPMENT_SELECTION);
    setIsEditing(true);
  }

  function handleEditUser(email: string) {
    setStep(ACCESS_SHARING_EQUIPMENT_STEPS.ACCESS_SHARING);
    setEditableUserEmail(email);
    setIsEditing(true);
  }

  function handleAddUser() {
    setStep(ACCESS_SHARING_EQUIPMENT_STEPS.ACCESS_SHARING);
    setEditableUserEmail(undefined);
    setIsEditing(true);
  }

  const handleSubmit = useTryMutate(
    () => {
      setIsLoading(true);
      const equipmentIds = Array.from(selectedEquipmentIds.values());
      const users = Array.from(selectedUsers.values());
      return EquipmentService.share({ equipmentIds, users });
    },
    {
      onComplete: (res, err, arg, api) => {
        setIsLoading(false);

        if (err) {
          api.showErrorModal();
        } else {
          api.successNotification(
            t('access_sharing.notifications.invitation-successfully-sent'),
          );
          setIsSubmitted(true);
          // NOTE: We add a brief delay before calling onBack to ensure that
          // useNavigationPrompt has enough time to update its state and avoid showing the
          // ConfirmNavigateAwayDialog.
          setTimeout(onBack, 100);
        }
      },
    },
  );

  return (
    <EquipmentsSharingOverviewView
      equipment={equipment}
      selectedEquipmentIds={selectedEquipmentIds}
      selectedUsers={selectedUsers}
      isLoading={isLoading}
      setSelectedUsers={setSelectedUsers}
      onEditEquipment={handleEditEquipment}
      onEditUser={handleEditUser}
      onAddUser={handleAddUser}
      onBack={onBack}
      onSubmit={handleSubmit}
    />
  );
}
