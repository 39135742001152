import {
  BackActionContentLayout,
  BackActionContentLayoutProps,
} from 'app/components/layouts/BackActionContentLayout';
import React from 'react';
import './BackActionSplitCardContentLayout.less';
import { Card } from 'app/components/Card';
import clsx from 'clsx';

interface Props {
  className?: string;
  renderHeader: React.ComponentType;
  renderContent: React.ComponentType;
  backActionProps: Omit<BackActionContentLayoutProps, 'children'>;
}

export default function BackActionSplitCardContentLayout({
  className,
  renderHeader: Header,
  renderContent: Content,
  backActionProps,
}: Props) {
  return (
    <BackActionContentLayout
      className={clsx('back_action_split_card_content_layout', className)}
      backIconProps={{
        className:
          'back_action_split_card_content_layout__navigation-icon-button',
      }}
      {...backActionProps}
    >
      <Card className="back_action_split_card_content_layout__card">
        <div className="back_action_split_card_content_layout__header">
          <Header />
        </div>

        <div className="back_action_split_card_content_layout__content">
          <Content />
        </div>
      </Card>
    </BackActionContentLayout>
  );
}
